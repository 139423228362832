import { Container, Paper, Box, TAGTypography, dataLayerConfig } from '@aspendental/design-system'
import React from 'react'
import UserContext from '../../contexts/userContext/user-context'
import { needAssistancePhoneLink } from '../../utils/Format'
import { formatPhoneNumber } from '../../utils/Helper'
import FloatingButton from './FloatingButton'
interface IRescheduleTemplateProps {
  enfant: React.ReactNode[] | React.ReactNode
  path: string
  isNormalSize: boolean
  buttonHandler?: (event: any) => void
  buttonText?: string
  disabledButton?: boolean
  headingText: string
  needAssistanceText: string
  phonNumber?: string
  eventType?: string
  stepName?: string
}

const RescheduleTemplate = (props: IRescheduleTemplateProps) => {
  /* This will display the abandonment dialog. it cannot be customized. see the following link:
  https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup */
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser()) {
    window.onbeforeunload = () => ''
  }
  const userCtx = React.useContext(UserContext)
  const officePhoneNumber = userCtx.visitInfo?.phoneNumber ? userCtx.visitInfo?.phoneNumber : props.phonNumber

  React.useEffect(() => {
    // Additionally wanted step events for rescheduling flow
    dataLayerConfig({
      event: props.eventType,
      step_name: props.stepName
    });
  }, [])

  return (
    <>
      <Container maxWidth="lg" disableGutters={!props.isNormalSize} sx={{ marginY: 2 }}>
        <Paper
          variant={props.isNormalSize ? 'outlined' : 'elevation'}
          elevation={0}
          sx={{
            padding: props.isNormalSize ? '20px' : '0px',
          }}
        >
          {props.headingText && (
            <Box
              sx={{
                textAlign: props.isNormalSize ? 'left' : 'center',
              }}
            >
              <TAGTypography
                variant={'overline'}
                color="success.dark"
                marginY={2}
                sx={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  padding: props.isNormalSize ? '0px 0px 0px 20px' : '20px 0px',
                }}
                id={'appointmentAssistantTitle'}
              >
                {props.headingText}
              </TAGTypography>
            </Box>
          )}
          {props.enfant}{' '}
        </Paper>
        {props.isNormalSize && (
          <>
            {needAssistancePhoneLink(
              props.needAssistanceText + formatPhoneNumber(officePhoneNumber ?? '(800) 277-3633'),
              false,
            )}
          </>
        )}
      </Container>
      {!props.isNormalSize && (
        <Box my={2} sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
          {needAssistancePhoneLink(
            props.needAssistanceText + formatPhoneNumber(officePhoneNumber ?? '(800) 277-3633'),
            true,
          )}
        </Box>
      )}
      {props.buttonHandler && props.buttonText && (
        <FloatingButton
          disabledButton={props.disabledButton}
          buttonText={props.buttonText}
          buttonHandler={props.buttonHandler}
        />
      )}
    </>
  )
}

export default RescheduleTemplate

import React from 'react'
import { Container } from '@aspendental/design-system'

export interface IFormsMenuTemplateProps {
  isNormalSize: boolean
  children: React.ReactNode | React.ReactNode[] | undefined
}
const FormsMenuTemplate = (props: IFormsMenuTemplateProps) => {
  return (
    <Container maxWidth="lg" sx={{ minHeight: '60vh', alignItems: 'center', textAlign: 'center' }}>
      {props.children}
    </Container>
  )
}

export default FormsMenuTemplate

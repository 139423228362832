import { TAGNotification } from '@aspendental/design-system'
import React from 'react'

/* This component renders an error message when you did not select a 
TimeButton which is actually in the DateTimeTemplateProper */
const RJSFTimeButtons = (props: any) => {
  const errorMsg = props.rawErrors
    ? props.rawErrors.length > 0 && props.rawErrors[0] === 'Appointment Timecode should be string'
      ? `Please select a time`
      : props.rawErrors[0]
    : ''
  return (
    <>
      {props.rawErrors && errorMsg && (
        <TAGNotification elevated={false} theme={'Error'}>
          {errorMsg}
        </TAGNotification>
      )}
    </>
  )
}

export default RJSFTimeButtons

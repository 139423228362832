import React from 'react'
import { TAGDateRangePicker, FormControl, useMediaQuery, useTheme } from '@aspendental/design-system'
import { DateRange } from '@mui/lab'
import {
  DayOfWeekType,
  AMPMType,
} from '@aspendental/design-system/lib/components/elements/TAGDateTimePicker/TAGPickerFilterTabContent'
import { renderFromHelper } from './renderError'
import { addDays } from 'date-fns'

const RJSFTimePicker = (props: any) => {
  const [valueArray, setValueArray] = React.useState<DateRange<Date>>(props.formData.timeRanges || [null, null])
  const [ampm, setAmpm] = React.useState('ALL' as AMPMType)
  const [dayOfWeek, setDayOfWeek] = React.useState<DayOfWeekType[]>(() => [])
  const isLargeScreen = useMediaQuery('(min-width:700px)')
  const theme = useTheme()

  const ampmHandler = (event?: React.ChangeEvent<HTMLInputElement>) => {
    setAmpm((event?.target as HTMLInputElement).value as AMPMType)
    props.schema.timeOfDay = (event?.target as HTMLInputElement).value as AMPMType
  }
  const handleDayOfWeek = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => {
    const newValue = (event?.currentTarget as HTMLInputElement).value as DayOfWeekType
    if (dayOfWeek && dayOfWeek.includes(newValue)) {
      setDayOfWeek(dayOfWeek.filter((item: string) => item !== newValue))
      props.schema.weekDays = dayOfWeek.filter((item: string) => item !== newValue)
    } else {
      setDayOfWeek(dayOfWeek.concat(newValue))
      props.schema.weekDays = dayOfWeek.concat(newValue)
    }
  }
  return (
    <FormControl
      color="secondary"
      fullWidth
      focused
      sx={{
        '& .MuiFormLabel-root': {
          background: '#ffffff',
          fontFamily: theme.typography.bodyMediumBook,
          fontWeight: '300',
          transform: 'translate(14px, -8px) scale(0.75)',
          transformOrigin: 'top left',
          padding: '0 5px',
        },
      }}
    >
      <TAGDateRangePicker
        color={'secondary'}
        wrapper={'Dialog'}
        minDate={new Date()}
        maxDate={addDays(new Date(), 60)}
        onChange={newValue => {
          setValueArray(newValue)
          props.onChange({
            timeOfDay: ampm,
            weekDays: dayOfWeek,
            timeRanges: newValue,
          })
        }}
        fieldError={props.rawErrors?.length > 0}
        label={props.schema.label}
        value={valueArray}
        ampmButton={ampm}
        onChangAmPm={ampmHandler}
        weekOfDayButton={dayOfWeek}
        onChangesetWeekOfDay={handleDayOfWeek}
        isLargeScreen={isLargeScreen}
        onResetFilter={() => {
          setAmpm('ALL')
          setDayOfWeek([])
          props.schema.timeOfDay = 'ALL'
          props.schema.weekDays = []
        }}
        id="datePicker"
      />
      {renderFromHelper(props.rawErrors)}
    </FormControl>
  )
}

export default RJSFTimePicker

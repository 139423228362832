import { FormControl, FormGroup, Grid, TAGCheckboxInput } from '@aspendental/design-system'
import React from 'react'
import { renderFromHelper } from './renderError'

const RJSFCheckboxGroupField = (props: any) => {
  const [value, setValue] = React.useState<string[]>([])
  React.useEffect(() => {
    if (value.length > 0) {
      props.onChange(value)
    } else {
      props.onChange(undefined)
    }
  }, [value])
  return (
    <Grid item container direction={'column'} sx={{ textAlign: 'start' }}>
      <FormControl required>
        <FormGroup
          row
          {...props}
          value={props.formData || undefined}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
              setValue(val => [...val, event.target.value])
            } else {
              setValue(val => val.filter(text => text !== event.target.value))
            }
          }}
        >
          {props.schema.items.enum.map((item: string) => (
            <TAGCheckboxInput key={item} value={item} inputLabel={item} />
          ))}
        </FormGroup>
      </FormControl>
      <Grid item>{renderFromHelper(props.rawErrors)}</Grid>
    </Grid>
  )
}

export default RJSFCheckboxGroupField

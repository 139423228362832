import React from 'react'
import {
  TAGTypography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  CardProps,
  TAGLink,
} from '@aspendental/design-system'
import { formatPhoneNumber } from '../../../utils/Helper'
import { IAvailableLocation } from '../../../constants/type'
import DateSection from '../DateAndTime/DateSection'

export interface IAvailableNearByCardProps extends CardProps {
  location: IAvailableLocation
  isNormalSize: boolean
  updateSeletedButton: (btn: string) => void
  seletedBtn: string | undefined
  setNewNearby: (code: string) => void
  notificationText: string
  key: string
  numberOfDays: number
}

const AvailableNearByCard: React.FC<IAvailableNearByCardProps> = (props: IAvailableNearByCardProps) => {
  const { addressLine1, addressLine2, avaliableTimes, distance, facilityCode, location, phoneNumber } = props.location
  const cardStyle = props.isNormalSize
    ? { border: '1px solid #D6D9DA', marginBottom: '20px' }
    : { bgcolor: 'neutrals.neutral', marginBottom: '2px' }

  return (
    <Card
      elevation={0}
      sx={[cardStyle, { paddingX: '5px' }]}
      square={!props.isNormalSize}
      className={'nearByLocationCard'}
    >
      <CardContent sx={{ padding: props.isNormalSize ? '32px' : '15px 10px' }}>
        <Grid container justifyContent={'space-between'}>
          <TAGTypography
            variant={props.isNormalSize ? 'h4' : 'bodyMediumBold'}
            color={'primary.main'}
            className={'nearbyCardLocation'}
          >
            {location || 'Location'}
          </TAGTypography>
          <TAGTypography color="text.tertiary" variant={'bodyMediumBook'} className={'nearbyCardDistance'}>
            {distance || 'Distance'}
          </TAGTypography>
        </Grid>
        <Grid container direction={'column'}>
          <Grid item>
            <TAGTypography
              color="text.secondary"
              variant={props.isNormalSize ? 'bodyMediumBook' : 'bodySmallBook'}
              className={'nearbyCardAddressLine1'}
            >
              {addressLine1 || 'Address Line 1'}
            </TAGTypography>
          </Grid>
          <Grid item>
            <TAGTypography
              color="text.secondary"
              variant={props.isNormalSize ? 'bodyMediumBook' : 'bodySmallBook'}
              className={'nearbyCardAddressLine2'}
            >
              {addressLine2 || 'Address Line 2'}
            </TAGTypography>
          </Grid>
          <Grid item>
            <TAGTypography
              variant={props.isNormalSize ? 'bodyMediumBook' : 'bodySmallBook'}
              color="primary.main"
              className={'nearbyCardPhoneNumber'}
            >
              <TAGLink
                underline="hover"
                variant="bodySmallBook"
                sx={{
                  color: 'text.link',
                  cursor: 'pointer',
                }}
                href={`tel:${phoneNumber}`}
              >
                {formatPhoneNumber(phoneNumber) || 'Phone Number'}
              </TAGLink>
            </TAGTypography>
          </Grid>
        </Grid>
      </CardContent>
      <DateSection
        availableTime={(avaliableTimes as unknown as { timeslot: string; timeslotUid: string }[]) || null}
        isNormalSize={props.isNormalSize}
        seletedBtn={props.seletedBtn}
        updateSeletedButton={props.updateSeletedButton}
        facilityCode={facilityCode}
        phoneNumber={phoneNumber}
        numberOfDays={props.numberOfDays}
        notificationText={props.notificationText}
      />
      <CardActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={() => {
            props.setNewNearby(facilityCode)
            window.scrollTo(0, 0)
          }}
          size="small"
          className="viewAllTimesButton"
        >
          <TAGTypography
            color="secondary"
            variant="bodySmallSemiBold"
            sx={{
              fontWeight: 700,
              letterSpacing: '1px',
            }}
          >
            View all available times
          </TAGTypography>
        </Button>
      </CardActions>
    </Card>
  )
}

export default AvailableNearByCard

import React from 'react'
import { RichText } from '@aspendental/design-system'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import SubmitButton from '../SubmitButton/SubmitButton'
import { useNavigate } from '@reach/router'
export interface IOneClickLinkExpiredPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}
const OneClickLinkExpiredPage = (props: IOneClickLinkExpiredPageProps) => {
  const navigate = useNavigate()
  const richText = props.pageContent?.contents[0]?.content?.raw

  const submitHandler = () => {
    navigate('/patient-services/')
  }
  return (
    <FormsMenuTemplate isNormalSize={props.isNormalSize}>
      <FormCredentialTitles isNormalSize={props.isNormalSize} title={props.pageContent.title}>
        {richText && (
          <RichText
            centerAlign
            branding={'aspendental'}
            content={{
              raw: richText,
              references: [],
            }}
          ></RichText>
        )}
      </FormCredentialTitles>
      <SubmitButton
        buttonVariant="Secondary Default"
        isNormalSize={props.isNormalSize}
        submitHandler={submitHandler}
        buttonText={'Back To Patient Service'}
      />
    </FormsMenuTemplate>
  )
}

export default OneClickLinkExpiredPage

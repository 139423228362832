import React from 'react'
import {
  TAGTextButton,
  TAGTypography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TAGOutlinedButton,
  useTheme,
} from '@aspendental/design-system'
import FilterListIcon from '@mui/icons-material/FilterList'

interface IFilterDialogProps {
  children: React.ReactNode
  onApply: () => void
}
const FilterDialog = (props: IFilterDialogProps) => {
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <TAGOutlinedButton
        id={'filterButton'}
        buttonTheme="OUTLINEDANDTEXTBLUE"
        size="small"
        startIcon={<FilterListIcon />}
        sx={{ borderRadius: '40px' }}
        onClick={handleClickOpen}
      >
        <TAGTypography variant={'buttonLarge'} sx={{ display: { xs: 'none', lg: 'block' } }}>
          Filter
        </TAGTypography>
      </TAGOutlinedButton>
      <Dialog open={open} fullWidth onClose={handleClose} sx={{ padding: '50px 100px' }} className="filterDialog">
        <DialogTitle>
          <TAGTypography variant={'h3'}>{'Filter by'}</TAGTypography>
        </DialogTitle>
        <DialogContent className="dialogContent">{props.children}</DialogContent>
        <DialogActions sx={{ backgroundColor: theme.palette.backgrounds.neutral }}>
          <TAGTextButton onClick={handleClose} className="cancelButton">
            <TAGTypography variant="buttonLarge" color="primary">
              Cancel
            </TAGTypography>
          </TAGTextButton>
          <TAGTextButton
            onClick={() => {
              props.onApply()
              handleClose()
            }}
            className="applyButton"
          >
            <TAGTypography variant="buttonLarge" color="secondary">
              Apply
            </TAGTypography>
          </TAGTextButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FilterDialog

import { Grid, TAGTypography, TAGNotification, TAGLink } from '@aspendental/design-system'
import React from 'react'
import { formatPhoneNumber, timeWithoutTimeZone } from '../../../utils/Helper'
import { format } from 'date-fns'
import DateSlider from './DateSlider'
import TimeButtonList from './TimeButtonList'

export interface IDateSectionProps {
  availableTime: { timeslot: string; timeslotUid: string }[] | null
  facilityCode: string
  isNormalSize: boolean
  updateSeletedButton: (btn: string) => void
  seletedBtn: string | undefined
  phoneNumber?: string
  numberOfDays?: number
  notificationText: string
}

const DateSection = (props: IDateSectionProps) => {
  const groupbyDate =
    props.availableTime &&
    props.availableTime.length > 0 &&
    props.availableTime.reduce(
      (
        groups: { [key: string]: { timeslot: string; timeslotUid: string }[] },
        time: { timeslot: string; timeslotUid: string },
      ) => {
        const dateTime = timeWithoutTimeZone(time.timeslot) //this date will change to local date, if the user is outside America, displayed time will be wrong
        const dateIdx: string = format(dateTime, 'iii, LLL d')
        if (!groups[dateIdx]) {
          groups[dateIdx] = []
        }
        groups[dateIdx].push(time)
        return groups
      },
      {},
    )

  return (
    <>
      {props.availableTime?.length !== 0 ? (
        Object.entries(groupbyDate || [])
          .slice(0, props.numberOfDays || 7)
          .map(([key, value]) => (
            <Grid key={key}>
              <TAGTypography
                variant={'bodySmallBook'}
                color="text.primary"
                pl={props.isNormalSize ? 5 : 1}
                mb={1}
                className={`dateTitle${key}`}
              >
                {key}
              </TAGTypography>
              <DateSlider isNormalSize={props.isNormalSize}>
                <TimeButtonList
                  date={key}
                  seletedBtn={props.seletedBtn}
                  updateSeletedButton={props.updateSeletedButton}
                  avaliableTimes={value}
                  facilityCode={props.facilityCode}
                  isNormalSize={props.isNormalSize}
                />
              </DateSlider>
            </Grid>
          ))
      ) : (
        <Grid sx={{ padding: '0px 20px' }}>
          <TAGNotification theme="Primary" elevated={false}>
            <TAGTypography>
              {props.notificationText}
              <TAGLink
                underline="hover"
                color="secondary"
                sx={{
                  cursor: 'pointer',
                }}
                href={`tel:+1${props.phoneNumber}`}
              >
                {(props.phoneNumber && formatPhoneNumber(props.phoneNumber)) || '(800) 277-3633'}
              </TAGLink>
            </TAGTypography>
          </TAGNotification>
        </Grid>
      )}
    </>
  )
}

export default DateSection

import React from 'react'
import { Grid, TAGButton, TAGTypography, dataLayerConfig } from '@aspendental/design-system'
import { format } from 'date-fns'
import { useNavigate } from '@reach/router'
import RescheduleSuccessCard from './RescheduleSuccessCard'
import { formatPhoneNumber, timeWithoutTimeZone } from '../../utils/Helper'
import UserContext from '../../contexts/userContext/user-context'
import { SessionType } from '../../constants/type'
interface IDTsuccessPageProps {
  isNormalSize: boolean
  pageContent?: any
  sessionItem?: SessionType
}

const RescheduleSuccessPage = (props: IDTsuccessPageProps) => {
  const userCtx = React.useContext(UserContext)
  const navigate = useNavigate()
  const isOneClickLogin = globalThis?.sessionStorage.getItem('isOneClickLogin') === 'true'

  React.useEffect(() => {
    // Virtual page views "needed" for SPA-related pages. includes entire rescheduling flow
    dataLayerConfig({
      event: 'virtualPageview',
      virtualPageURL: 'https://www.aspendental.com/patient-services/success',
      virtualPageTitle: 'Patient Services - Success'
    });

    if (
      !userCtx ||
      JSON.stringify(userCtx.rescheduleForm) === '{}' ||
      !userCtx.rescheduleForm ||
      !userCtx.rescheduleForm.location ||
      !userCtx.rescheduleForm.appointmentTimecode
    ) {
      isOneClickLogin ? navigate('/patient-services/') : navigate('/patient-services/appointment')
    }
  }, [])

  const handleClick = () => {
    dataLayerConfig({
      event: 'patient_services_back_click',
      link_text: 'Back to Patient Service', 
      link_url: 'https://www.aspendental.com/patient-services'
    })

    navigate('/patient-services')
  }

  const appointment = userCtx.rescheduleForm?.appointmentTimecode?.split('$')
  const appointmentAddress = userCtx.rescheduleForm.location
  const { addressLine1, addressLine2, phoneNumber } = appointmentAddress || {}
  const appointmentDate = appointment && format(timeWithoutTimeZone(appointment[1].substring(0, 29)), 'PPPP') //'Wednesday, November 17, 2021',
  const appointmentTime = appointment && format(timeWithoutTimeZone(appointment[1].substring(0, 29)), 'p')
  const addToCalenderObj = {
    startDate: appointment && appointment[1].substring(0, 29),
    description: 'Aspen dental Appointment',
    address1: addressLine1,
    address2: addressLine2,
  }
  return (
    <>
      <TAGTypography
        variant="h3"
        sx={{ fontSize: props.isNormalSize && '25px', padding: '20px' }}
        color="text.primary"
        id={'pageTitle'}
      >
        {`${props.pageContent.confirmationPageName}, ${userCtx.user.firstName}!`}
      </TAGTypography>
      <Grid
        container
        sx={{
          padding: props.isNormalSize ? '0px 16px 16px 5px' : '20px',
          justifyContent: props.isNormalSize ? 'flex-start' : 'center',
        }}
      >
        <RescheduleSuccessCard
          isNormalSize={props.isNormalSize}
          infoCards={[
            {
              icon: 'AspenOffice',
              subtitles: [addressLine1, addressLine2 || '', formatPhoneNumber(phoneNumber)],
            },
            {
              icon: 'CalendarDateTime',
              subtitles: [appointmentDate, appointmentTime],
              button: addToCalenderObj,
            },
          ]}
        />
      </Grid>
      <Grid
        sx={{
          padding: props.isNormalSize ? '0px 16px 16px 5px' : '5px 20px',
        }}
      >
        <TAGTypography variant={props.isNormalSize ? 'bodyMediumBook' : 'bodySmallBook'}>
          {props.pageContent.footerText.footerText}
        </TAGTypography>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          padding: props.isNormalSize ? '0px 16px 16px 5px' : '20px',
          alignItems: props.isNormalSize ? 'flex-start' : 'center',
          justifyContent: props.isNormalSize ? 'flex-start' : 'center',
        }}
      >
        <TAGButton
          buttonVariant={'Primary Default'}
          buttonSize={'small'}
          fullWidth={false}
          onClick={() => handleClick()}
        >
          Back To Patient Service
        </TAGButton>
      </Grid>
    </>
  )
}

export default RescheduleSuccessPage

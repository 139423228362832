import { ILoginPayload, toUrlEncoded } from '../utils/Helper'
import { apiClient } from './apiClient'

const endpoints = {
  emailLoginLink: 'bbff/identity/start-email-link-login',
  loginViaEmailLink: 'bbff/identity/finish-email-link-login',
  patientLogin: 'bbff/aspendental/patient/login/',
  patientLoginWithToken: 'scbff/identity/connect/token',
  validateToken: 'session',
}

export default {
  authenticatePatient: (payload: object) => apiClient.loginpost(endpoints.patientLogin, payload),
  authenticatePatientWithToken: (payload: ILoginPayload) =>
    apiClient.authpost(endpoints.patientLoginWithToken, toUrlEncoded(payload)),
  sendAuthenticationEmailLink: (payload: object) => apiClient.post(endpoints.emailLoginLink, payload),
  authenticateViaEmailLink: (payload: object, token: string) =>
    apiClient.finishemailpost(endpoints.loginViaEmailLink, payload, token),
  validateToken: (token: string) => apiClient.validateToken(endpoints.validateToken, token),
}

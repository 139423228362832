import React from 'react'
import { TAGTypography, Card, CardContent, Grid, CardProps } from '@aspendental/design-system'
import { formatPhoneNumber } from '../../../utils/Helper'

export type ILocation = {
  addressLine1: string
  addressLine2?: string
  distance: string
  facilityCode: string
  location: string
  phoneNumber: string
}

export interface INearbyLocationCardProps extends CardProps {
  location: ILocation
  matchPhoneSize: boolean
}

const NearbyLocationCard: React.FC<INearbyLocationCardProps> = (props: INearbyLocationCardProps) => {
  const matches = false
  const { addressLine1, addressLine2, distance, location, phoneNumber } = props.location
  const cardStyle = matches
    ? { background: 'neutrals.neutral' }
    : { borderWidth: '1px', borderStyle: 'solid', borderColor: 'neutrals.primary' }

  return (
    <Card elevation={0} sx={[cardStyle, { width: '100%' }]} square={matches && true} className="locationCard">
      <CardContent sx={{ padding: matches ? '15px 10px' : '12px', overflow: 'auto' }}>
        <Grid container justifyContent={'space-between'}>
          <TAGTypography
            className="findMoreOfficesLocation"
            variant={matches ? 'bodyMediumBold' : 'h4'}
            color="primary"
            sx={{ fontSize: matches ? '' : `calc(1.1vw + .7em)` }}
          >
            {location || 'Location'}
          </TAGTypography>
          <TAGTypography color="text.tertiary" variant={'bodyMediumBook'} className="findMoreOfficesDistance">
            {distance || 'Distance'}
          </TAGTypography>
        </Grid>
        <Grid container direction={'column'}>
          <Grid item>
            <TAGTypography
              color="text.secondary"
              variant={matches ? 'bodySmallBook' : 'bodyMediumBook'}
              className="findMoreOfficesAddressLine1"
            >
              {addressLine1 || 'Address Line 1'}
            </TAGTypography>
          </Grid>
          <Grid item>
            <TAGTypography
              color="text.secondary"
              variant={matches ? 'bodySmallBook' : 'bodyMediumBook'}
              className="findMoreOfficesAddressLine2"
            >
              {addressLine2 || 'Address Line 2'}
            </TAGTypography>
          </Grid>
          <Grid item>
            <TAGTypography
              variant={matches ? 'bodySmallBook' : 'bodyMediumBook'}
              color="primary"
              className="findMoreOfficesPhoneNumber"
            >
              {formatPhoneNumber(phoneNumber || 'Phone Number')}
            </TAGTypography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default NearbyLocationCard

import { Grid, TAGCheckboxInput } from '@aspendental/design-system'
import React from 'react'
import { renderFromHelper } from './renderError'
const RJSFCheckboxField = (props: any) => {
  return (
    <Grid item container direction={'column'} sx={{ textAlign: 'start' }}>
      <Grid item>
        <TAGCheckboxInput
          {...props}
          fullWidth
          color="tertiary.dark"
          required={props.required}
          fieldError={props.rawErrors?.length > 0}
          inputLabel={props.schema.label}
          fieldHelperText={props.rawErrors}
          checked={props.formData || false}
          value={props.formData || false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const check = (event.target as HTMLInputElement).checked
              ? (event.target as HTMLInputElement).checked
              : false
            props.onChange(check)
          }}
          sx={{ color: 'text.secondary' }}
        />
      </Grid>
      <Grid item>{renderFromHelper(props.rawErrors)}</Grid>
    </Grid>
  )
}
export default RJSFCheckboxField

import { TAGLink, TAGTypography } from '@aspendental/design-system'
import React from 'react'

export const needAssistancePhoneLink = (text: string, centered: boolean) => {
  if (!text) {
    return <></>
  }
  const splitPhoneNumber = text.substring(text.length - 14)
  const splitText = text.substring(0, text.length - 14)
  return (
    <TAGTypography
      variant={'bodySmallBook'}
      color="primary.main"
      sx={{ textAlign: centered && 'center' }}
      id={'needAssistanceText'}
    >
      <>{splitText}</>
      <TAGLink
        underline="hover"
        variant="bodySmallBook"
        sx={{
          color: 'text.link',
          cursor: 'pointer',
        }}
        href={`tel:${splitPhoneNumber}`}
      >
        {`${splitPhoneNumber}`}
      </TAGLink>
    </TAGTypography>
  )
}

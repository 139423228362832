import React from 'react'
import { Divider, Grid, TAGTypography } from '@aspendental/design-system'

const DynamicTemplate = (props: any) => {
  let grid = 0
  const dynamicForm = () => {
    return (
      <>
        {props.properties &&
          props.properties.map((sch: any) => {
            if (sch.content.props.uiSchema['ui:createNewLine']) {
              grid = 0
            } else {
              grid += sch.content.props.uiSchema['ui:size']
            }
            return (
              <React.Fragment key={sch.name}>
                <Grid
                  item
                  xs={sch.content.props.uiSchema['ui:phoneSize']}
                  sm={sch.content.props.uiSchema['ui:size'] || 6}
                >
                  {sch.content}
                </Grid>
                {sch.content.props.uiSchema['ui:createNewLine'] && (
                  <Grid item xs={sch.content.props.uiSchema['ui:phoneSize']} sm={12 - grid}></Grid>
                )}
                {sch.content.props.uiSchema['ui:divider'] && (
                  <Grid item xs={12}>
                    <Divider sx={{ width: '100%' }} flexItem />
                  </Grid>
                )}
              </React.Fragment>
            )
          })}
      </>
    )
  }
  return (
    <>
      {(props.title || props.description) && (
        <Grid container xs={12} sx={{ paddingBottom: 2, textAlign: 'start' }} direction={'column'}>
          {props.schema.label && (
            <TAGTypography
              variant={props.schema.titleFont ? props.schema.titleFont : 'bodyMediumBold'}
              color={props.schema.titleColor ? props.schema.titleColor : 'text.secondary'}
            >
              {props.schema.label}
            </TAGTypography>
          )}
          {props.description && (
            <TAGTypography
              variant={props.schema.descriptionFont ? props.schema.descriptionFont : 'lowestLevel'}
              color={props.schema.descriptionColor ? props.schema.descriptionColor : 'text.secondary'}
            >
              {props.description}
            </TAGTypography>
          )}
        </Grid>
      )}
      <Grid container spacing={2}>
        {dynamicForm()}
      </Grid>
    </>
  )
}

export default DynamicTemplate

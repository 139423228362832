import React from 'react'
import { TAGSelectDropdown, SelectChangeEvent, FormControl, useTheme } from '@aspendental/design-system'

const RJSFSelectField = (props: any) => {
  const [selected, setSelected] = React.useState<string>(props.formData || undefined)
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)
  const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false)
  const theme = useTheme()

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value)
    props.onChange(event.target.value)
    setDropdownOpen(false)
  }

  // The following 4 functions have been added for the ability of the mobile drawer.
  // The selected state is ultimatlely the selected option
  const handleDrawer = () => {
    if (window.innerWidth < 600) {
      setDropdownOpen(false)
      setDrawerOpen(!drawerOpen)
    }
    setDropdownOpen(true)
  }

  const handleMobileSelect = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setDropdownOpen(false)
    setDrawerOpen(false)
    const item = event.target as HTMLLIElement
    setSelected(item.outerText)
    props.onChange(item.outerText)
  }

  const clearMobileOptions = () => {
    setDropdownOpen(false)
    setDrawerOpen(false)
    setSelected('')
  }

  const closeMobile = () => {
    setDrawerOpen(false)
    setDropdownOpen(false)
  }

  const sortItem = (items: { title: string }[]) =>
    items.sort((a: { title: string }, b: { title: string }) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))

  const itemsMapped =
    props.schema.items.enum &&
    props.schema.items.enum.length > 0 &&
    sortItem(props.schema.items.enum).map((item: any) => {
      return { divider: false, itemText: item.title, iconName: 'Clock', iconBoolean: false }
    })
  const requiredLabel = props.required ? ' *' : ''
  return (
    <FormControl fullWidth color="secondary" required={props.required}>
      <TAGSelectDropdown
        color="secondary"
        required={props.required}
        items={itemsMapped || []}
        formLabel={`${props.schema.label} ${requiredLabel}`}
        clearOptionText={'Select one'}
        fieldError={props.rawErrors?.length > 0}
        fieldDisabled={false}
        fieldHelperText={props.rawErrors}
        iconName={'Clock'}
        dropdownArrowBlue={true}
        selected={selected || props.formData}
        drawerOpen={drawerOpen}
        dropdownOpen={dropdownOpen}
        renderValue={(value: any) => value}
        handleChange={handleChange}
        handleDrawer={handleDrawer}
        handleMobileSelect={handleMobileSelect}
        clearMobileOptions={clearMobileOptions}
        closeMobile={closeMobile}
        sx={{
          '& .MuiFormLabel-root': {
            background: '#ffffff',
            fontFamily: theme.typography.bodyMediumBook,
            fontWeight: '300',
          },
        }}
      />
    </FormControl>
  )
}

export default RJSFSelectField

import React from 'react'
import { Container, Grid, HeroV2, TAGTypography } from '@aspendental/design-system'
import { useMutation } from '@apollo/client'
import { POST_CONFIRM_APPT_BY_APPOINTMENT_ID } from '../../constants/graphqlquery'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import Bugsnag from '@bugsnag/js'
import { IOneClickProps } from '../OneClickPrePage/OneClickPrePage'
import { format } from 'date-fns'
import RescheduleTemplate from '../ReschedulePage/RescheduleTemplate'
import RescheduleReviewCard, { IRescheduleReviewCardProps } from '../RescheduleReviewPage/RescheduleReviewCard'
import { formatPhoneNumber, timeWithoutTimeZone } from '../../utils/Helper'

type IOneClickConfirmationPageProps = IOneClickProps

const OneClickConfirmationPage = (props: IOneClickConfirmationPageProps) => {
  const [confirmAppt, { loading, error, data }] = useMutation<{
    me: { confirmVisit: any; errors: any }
  }>(POST_CONFIRM_APPT_BY_APPOINTMENT_ID)
  const appointmentInfo = data?.me?.confirmVisit?.result
  const addressLine2 = `${appointmentInfo?.facility?.name}  ${appointmentInfo?.facility?.address.zipCode}`
  const errorHero = props.pageContent?.contents[0]
  const cardData: IRescheduleReviewCardProps[] = [
    {
      title: 'Office Location',
      subtitles: [
        appointmentInfo?.facility?.name,
        appointmentInfo?.facility?.address?.address1 + ' ' + addressLine2 || '',
        formatPhoneNumber(appointmentInfo?.facility?.phoneNumber) || '',
      ],
      icon: 'AspenOffice',
    },
    {
      title: 'Date & Time',
      subtitles: [
        appointmentInfo?.scheduledDateTime && format(timeWithoutTimeZone(appointmentInfo?.scheduledDateTime), 'PPPP'),
        appointmentInfo?.scheduledDateTime && format(timeWithoutTimeZone(appointmentInfo?.scheduledDateTime), 'p'),
      ],
      icon: 'CalendarDateTime',
    },
  ]
  React.useEffect(() => {
    const confirmAppointment = async () => {
      try {
        if (props.visitId) {
          await confirmAppt({
            variables: {
              input: {
                visitId: props.visitId,
              },
            },
          })
        }
      } catch (err: any) {
        Bugsnag.notify(JSON.stringify(err))
      }
    }
    confirmAppointment()
  }, [])
  const noVisitsError = data && data.me.confirmVisit.errors

  return (
    <>
      {/* we can also create another condition to render the Appointment page.
      See OneClickReschedule for an easy example
    */}
      {!props.visitId || error || noVisitsError ? (
        <Container>
          <HeroV2
            branding={'aspendental'}
            darkOverlay={errorHero?.darkOverlay}
            focalPointImage={errorHero?.focalPointImage}
            contentAlign={errorHero?.contentAlign}
            contentPosition={errorHero?.contentPosition}
            bodySize={errorHero?.bodySize}
            bodyHero={errorHero?.body}
            headingHero={errorHero?.heading}
            headingSize={errorHero?.headingSize}
            contentPositionSmallScreen={errorHero?.contentSmallScreen}
            templateWidth={errorHero?.templateWidth}
            video={errorHero?.video}
          />
        </Container>
      ) : (
        <GraphQLTemplate
          loading={loading}
          error={error}
          data={data}
          sessionItem="isOneClickLogin"
          errorNavigate="/patient-services/one-click-expiration"
        >
          <RescheduleTemplate
            enfant={
              <>
                <TAGTypography
                  variant="h3"
                  sx={{ fontSize: props.isNormalSize && '25px', padding: '20px' }}
                  color="text.primary"
                  id={'pageTitle'}
                >
                  {`${props.pageContent.title}!`}
                </TAGTypography>
                <Grid
                  container
                  spacing={2}
                  sx={{ padding: props.isNormalSize ? '0px 16px 16px 5px' : '20px', overflow: 'auto' }}
                >
                  {cardData.map(card => (
                    <Grid item xs={12} sm={6} key={card.title}>
                      <RescheduleReviewCard title={card.title} subtitles={card.subtitles} icon={card.icon} />
                    </Grid>
                  ))}
                </Grid>
              </>
            }
            path={''}
            isNormalSize={props.isNormalSize ?? true}
            headingText={''}
            needAssistanceText={'Need further assistance? Call '}
            phonNumber={appointmentInfo?.facility?.phoneNumber}
          />
        </GraphQLTemplate>
      )}
    </>
  )
}

export default OneClickConfirmationPage

import React from 'react'
import {
  Box,
  BrandIconType,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TAGLink,
  TAGSvgIcon,
  TAGTypography,
} from '@aspendental/design-system'

export interface IRescheduleReviewCardProps {
  id?: string
  title?: string
  editLink?: string
  subtitles: string[]
  icon: BrandIconType
  editLinkHandler?: () => void
}

const RescheduleReviewCard = (props: IRescheduleReviewCardProps) => {
  return (
    <Card
      id={props.id}
      elevation={0}
      sx={{
        minWidth: '200px',
        minHeight: '150px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'neutrals.primary',
      }}
    >
      <CardHeader
        title={
          <TAGTypography
            id={`${props.id}-cardTitle`}
            variant={'h4'}
            color="primary.main"
            sx={{
              fontFamily: 'MessinaSansRegular',
              fontWeight: 400,
            }}
          >
            {props.title}
          </TAGTypography>
        }
        action={
          props.editLink && (
            <IconButton aria-label="settings">
              <TAGLink
                color="secondary"
                underline="hover"
                sx={{
                  cursor: 'pointer',
                  ':hover': { textDecoration: 'none' },
                }}
                onClick={props.editLinkHandler}
                variant={'bodyMediumSemiBold'}
              >
                {props.editLink}
              </TAGLink>
            </IconButton>
          )
        }
      />
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', paddingTop: '0px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '200px' }}>
          {props.subtitles.map((subtitle: string, idx: number) => (
            <TAGTypography
              key={`${subtitle}${idx}`}
              variant={'bodyLargeBook'}
              color="text.secondary"
              id={`${props.id}-subtitle${idx}`}
            >
              {subtitle}
            </TAGTypography>
          ))}
        </Box>
        <TAGSvgIcon icon={props.icon} sx={{ alignItems: 'end' }} />
      </CardContent>
    </Card>
  )
}

export default RescheduleReviewCard

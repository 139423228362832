import { Card, Box, TAGTypography, TAGSvgIcon, List, ListItem, ListItemIcon, TAGLink } from '@aspendental/design-system'
import { IconNames } from '@aspendental/design-system/lib/components/templates/AgendaStepper/ADAgendaStepper'
import React from 'react'
import { addToCalendar, toCamelCase } from '../../utils/Helper'
interface IRescheduleSuccessCardProps {
  infoCards: { icon: IconNames; subtitles: string[]; button?: { [key: string]: string } }[]
  isNormalSize: boolean
}

const RescheduleSuccessCard = (props: IRescheduleSuccessCardProps) => {
  return (
    <Card
      elevation={0}
      sx={{
        bgcolor: props.isNormalSize ? 'backgrounds.neutral' : 'backgrounds.white',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'neutrals.primary',
        overflow: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: props.isNormalSize ? 'row' : 'column',
      }}
    >
      {props.infoCards.map((info: { icon: IconNames; subtitles: string[]; button?: { [key: string]: string } }) => (
        <List dense={false} key={info.subtitles[0]}>
          <ListItem
            alignItems={props.isNormalSize ? 'flex-start' : 'center'}
            sx={{ display: 'flex', flexDirection: props.isNormalSize ? 'row' : 'column', boxSizing: 'border-box' }}
          >
            <ListItemIcon>
              <TAGSvgIcon icon={info.icon} />
            </ListItemIcon>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {info.subtitles.map((subtitle: string) => (
                <TAGTypography
                  key={subtitle}
                  variant={'bodyLargeBook'}
                  color="primary.main"
                  className={toCamelCase(subtitle)}
                >
                  {subtitle}
                </TAGTypography>
              ))}
              {info.button && (
                <TAGLink
                  underline="hover"
                  color="secondary"
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    info.button &&
                    addToCalendar(
                      info.button.startDate,
                      info.button.description,
                      info.button.address1,
                      info.button.address2,
                    )
                  }
                  variant={'bodyMediumBold'}
                >
                  {'Add To Calendar'}
                </TAGLink>
              )}
            </Box>
          </ListItem>
        </List>
      ))}
    </Card>
  )
}

export default RescheduleSuccessCard

import { PatientInformationParamsInput, PersonalInfoContext } from '../contexts/formContext/form.actions'
import { addMinutes } from 'date-fns'

export interface ILoginPayload {
  [key: string]: string
}

export const toUrlEncoded = (obj: ILoginPayload) =>
  Object.keys(obj)
    .map((k: string) => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
    .join('&')

export const contextToGraphFormat = (formData: PersonalInfoContext) => {
  const newFormatedData = {
    ...formData,
    patientInformation: {
      ...formData.patientInformation,
      suffix:
        formData.patientInformation.suffix && formData.patientInformation.suffix !== 'None'
          ? formData.patientInformation.suffix
          : '',
    },
    insurance: {
      haveInsurance: formData?.insurance.doYouHaveInsurance === 'Yes',
      insuranceInfo: [
        {
          ...formData.insurance.insuranceInfo,
        },
      ],
    },
    responsibleParty: {
      responsiblePartyInfo: {
        city: formData?.responsibleParty.responsiblePartyInfo.city,
        country: formData?.responsibleParty.responsiblePartyInfo.country,
        dateOfBirth: formData?.responsibleParty.responsiblePartyInfo.dateOfBirth,
        firstName: formData?.responsibleParty.responsiblePartyInfo.firstName,
        lastName: formData?.responsibleParty.responsiblePartyInfo.lastName,
        state: formData?.responsibleParty.responsiblePartyInfo.state,
        street: formData?.responsibleParty.responsiblePartyInfo.street,
        suffix: formData?.responsibleParty.responsiblePartyInfo.suffix,
        zipCode: formData?.responsibleParty.responsiblePartyInfo.zipCode,
        sex: formData?.responsibleParty.responsiblePartyInfo.pISex,
      },
      isPatientResponsibleParty: formData?.responsibleParty.isPatientResponsibleParty,
    },
  }
  return newFormatedData
}

export const graphToContextFormat = (graphData: PatientInformationParamsInput) => {
  const newFormatedData = {
    ...graphData,
    insurance: {
      ...graphData?.insurance,
      doYouHaveInsurance: graphData?.insurance.haveInsurance ? 'Yes' : 'No',
      insuranceInfo: {
        ...graphData?.insurance.insuranceInfo[0],
      },
    },
    responsibleParty: {
      responsiblePartyInfo: {
        ...graphData?.responsibleParty.responsiblePartyInfo,
        pISex: graphData?.responsibleParty.responsiblePartyInfo.sex,
        address: graphData?.responsibleParty.responsiblePartyInfo.street,
      },
      isPatientResponsibleParty: graphData?.responsibleParty.isPatientResponsibleParty === 'True',
    },
  }
  return newFormatedData
}

export const camelCaseToTitleCase = (s: string) => {
  return s
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim()
}

export const toCamelCase = (str: string) => {
  if (!str) {
    return ''
  }
  return str
    .replace(/(?:^.|[A-Z]|\b.)/g, function (letter, index) {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    })
    .replace(/\s|[!@#$%^&*(),.?'":{}|<>]/g, '')
}

export const formatLocationList = (locList: any) => {
  if (!locList) {
    return []
  }
  return (
    locList.length > 0 &&
    locList.map((loc: any) => {
      return {
        location: loc.title,
        addressLine1: `${loc.address.line1}`,
        addressLine2: `${loc.address.city}, ${loc.address.state}   ${loc.zipCode}`,
        distance: `${loc.distance.toFixed(2)} mi`,
        phoneNumber: loc.phone,
        facilityCode: loc.facilityCode,
        aligners: 'Clear aligners available',
      }
    })
  )
}

export function formatPhoneNumber(phoneNumberString: string) {
  let newPhone = phoneNumberString
  if (phoneNumberString && phoneNumberString.length !== 10) {
    newPhone = `${phoneNumberString}`
  }
  const cleaned = ('' + newPhone).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const timeWithoutTimeZone = (ISOtime: string) => new Date(ISOtime.substring(0, ISOtime.length - 6))

export const formatNearByList = (nearby: any) => {
  return (
    nearby &&
    nearby.map((loc: any) => {
      return {
        location: loc.title,
        addressLine1: `${loc.address.line1}`,
        addressLine2: `${loc.address.city}, ${loc.address.state}   ${loc.zipCode}`,
        distance: `${loc.distance.toFixed(2)} mi`,
        phoneNumber: loc.phone,
        facilityCode: loc.facilityCode,
        avaliableTimes: loc.timeslots,
      }
    })
  )
}

export const restructSlots = (jsonSlots: { timeslot: string; timeslotUid: string }[]) => {
  if (!jsonSlots || jsonSlots.length === 0) {
    return []
  }
  return jsonSlots.map((item: { timeslot: string; timeslotUid: string }) => item) //item.timeslots
}

export const newformatNearByList = (nearby: any) => {
  return (
    nearby &&
    nearby.map((loc: any) => {
      const timeSlots = loc.facility.rescheduleSlots && restructSlots(loc.facility.rescheduleSlots.items) //BEFOREDONE TODO
      // right: '2023-02-08T17:00:00-06:00'
      // wrong: '2023-02-25T17:57:38.000Z'
      return {
        location: loc.facility.name,
        addressLine1: `${loc.facility.address?.address1}`,
        addressLine2: `${loc.facility.address?.city}, ${loc.facility.address?.stateCode}   ${loc.facility.address?.zipCode}`,
        distance: `${loc.distanceInMiles.toFixed(2)} mi`,
        phoneNumber: `${loc.facility.phoneNumber}`,
        facilityCode: loc.facility.code,
        avaliableTimes: timeSlots,
      }
    })
  )
}

export const newformatLocationList = (locList: any) => {
  if (!locList || locList.length === 0) {
    return []
  }
  return locList.map((loc: any) => {
    return {
      location: loc.facility.name,
      addressLine1: `${loc.facility.address?.address1}`,
      addressLine2: `${loc.facility.address?.city}, ${loc.facility.address?.stateCode}   ${loc.facility.address?.zipCode}`,
      distance: `${loc.distanceInMiles.toFixed(2)} mi`,
      phoneNumber: `${loc.facility.phoneNumber}`,
      facilityCode: loc.facility.code,
      aligners: 'Clear aligners available',
    }
  })
}

export const addToCalendar = (startDate: string, description: string, address1: string, address2: string) => {
  // Create the .ics URL
  const url = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    'DTSTART:' + new Date(startDate).toISOString().replaceAll('-', '').replaceAll(':', '').replaceAll('.000Z', 'Z'),
    'DTEND:' +
      addMinutes(new Date(startDate), 60)
        .toISOString()
        .replaceAll('-', '')
        .replaceAll(':', '')
        .replaceAll('.000Z', 'Z'),
    'SUMMARY:' + 'Aspen Dental Appointment',
    'DESCRIPTION:' + description,
    'LOCATION:' + address1 + ' ' + address2,
    'BEGIN:VALARM',
    'TRIGGER:-PT15M',
    'REPEAT:1',
    'DURATION:PT15M',
    'ACTION:DISPLAY',
    'DESCRIPTION:Reminder',
    'END:VALARM',
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n')

  window.open('data:text/calendar;charset=utf8,' + escape(url))
}

export const capitalized = (word: string) => {
  if (!word) {
    return ''
  }
  return word.charAt(0).toUpperCase() + word.slice(1)
}

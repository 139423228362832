import React from 'react'
import { TAGRadioButtonInput, TAGTypography, Grid } from '@aspendental/design-system'
import { renderFromHelper } from './renderError'

const RJSFBooleanField = (props: any) => {
  const radioItemsMapped = props.schema.enum.map((item: string) => {
    return { inputLabel: item, inputValue: item, fieldDisabled: false }
  })
  return (
    <Grid item container direction={'column'} sx={{ textAlign: 'start' }}>
      <Grid item>
        <TAGTypography variant={'bodyMediumBold'} color="text.secondary">
          {props.schema.label}
        </TAGTypography>
      </Grid>
      <Grid item>
        <TAGRadioButtonInput
          {...props}
          radioItems={radioItemsMapped}
          fullWidth
          color="tertiary.dark"
          required={props.required}
          fieldError={props.rawErrors?.length > 0}
          inputLabel={props.schema.label}
          fieldHelperText={props.rawErrors}
          value={props.formData || undefined}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange((event.target as HTMLInputElement).value)
          }}
          sx={{ color: 'text.secondary' }}
        />
      </Grid>
      <Grid item>{renderFromHelper(props.rawErrors)}</Grid>
    </Grid>
  )
}

export default RJSFBooleanField

import React from 'react'
import { TAGSvgIcon, Button, Box } from '@aspendental/design-system'

interface IDateSliderProps {
  isNormalSize: boolean
  children: React.ReactChild
}
const DateSlider = (props: IDateSliderProps) => {
  const scrl = React.useRef<HTMLInputElement>()
  const [scrollStart, setscrollStart] = React.useState(0)
  const [scrolEnd, setscrolEnd] = React.useState(false)
  const [scollButton, setScrollButton] = React.useState(false)

  const slide = (shift: number) => {
    if (!scrl.current) {
      return
    }
    scrl.current.scrollLeft += shift
    setscrollStart(scrollStart + shift)

    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  const scrollCheck = () => {
    if (!scrl.current) {
      return
    }
    setscrollStart(scrl.current.scrollLeft)
    if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
      setscrolEnd(true)
    } else {
      setscrolEnd(false)
    }
  }

  const isOverflowActive = () => {
    if (!scrl.current) {
      setScrollButton(true)
    } else {
      const isOverflow =
        scrl.current.offsetHeight < scrl.current.scrollHeight || scrl.current.offsetWidth < scrl.current.scrollWidth
      setScrollButton(isOverflow)
    }
  }

  React.useEffect(() => isOverflowActive())
  const fadeCondition = () => {
    if (!scollButton) {
      return ''
    }
    if (scrollStart === 0) {
      return 'linear-gradient(90deg, black 90%, transparent 100%)'
    } else if (!scrolEnd) {
      return 'linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%)'
    } else {
      return 'linear-gradient(270deg, black 90%, transparent 100%)'
    }
  }

  return (
    <Box sx={{ display: 'flex' }} mb={2}>
      {scollButton && (
        <Button
          className="timeSliderButtonLeft"
          onClick={() => slide(-200)}
          color="secondary"
          disabled={scrollStart === 0}
          sx={{
            display: props.isNormalSize ? 'flex' : 'none',
            minWidth: '30px',
            padding: '0px',
            color: 'secondary.main',
          }}
        >
          <TAGSvgIcon icon={'ChevronLeftFunc'} size={34} />
        </Button>
      )}
      <Box
        ref={scrl}
        onScroll={scrollCheck}
        className="container"
        sx={{
          paddingLeft: scollButton || !props.isNormalSize ? '0px' : '30px',
          cursor: 'grab',
          overflow: 'hidden',
          scrollBehavior: 'smooth',
          overflowX: 'scroll',
          '&::-webkit-scrollbar ': {
            background: 'transparent',
            '-webkit-appearance': 'none',
            width: 0,
            height: 0,
          },
          maskImage: fadeCondition,
          webkitMaskIimage: fadeCondition,
        }}
      >
        <Box sx={{ display: 'flex' }}>{props.children}</Box>
      </Box>
      {scollButton && (
        <Button
          className="timeSliderButtonRight"
          onClick={() => slide(+200)}
          disabled={scrolEnd}
          color="secondary"
          sx={{
            display: props.isNormalSize ? 'flex' : 'none',
            minWidth: '30px',
            padding: '0px',
            color: 'secondary.main',
          }}
        >
          <TAGSvgIcon icon={'ChevronRightFunc'} size={34} />
        </Button>
      )}
    </Box>
  )
}

export default DateSlider

import React from 'react'
import { TAGTypography, Grid, TAGCard } from '@aspendental/design-system'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'

export interface IPatientServicesPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}

const PatientServicesPage = (props: IPatientServicesPageProps) => {
  return (
    <FormsMenuTemplate isNormalSize={props.isNormalSize}>
      <FormCredentialTitles isNormalSize={props.isNormalSize} title={props.pageContent.title}>
        <TAGTypography variant={'bodyMediumBook'} color={'text.secondary'} gutterBottom>
          {props.pageContent.description}&nbsp;
          {props.isNormalSize && <br />}
        </TAGTypography>
      </FormCredentialTitles>
      <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
        {props.pageContent.contents[0].cards &&
          props.pageContent.contents[0].cards.map(
            (card: {
              name: string
              image: { url: string | undefined }
              imageAltText: string | undefined
              buttonText: string | undefined
              buttonUrl: string | undefined
              eventType: string | undefined
              stepName: string | undefined
            }) => (
              <Grid key={card.name} item xs={12} sm={4}>
                <TAGCard
                  className={'successFooterCard'}
                  theme={'BigImageOnTopButtons'}
                  elevationProp={false}
                  eyebrowColor="#027D5E"
                  eyebrowTextTransform="uppercase"
                  circleCheck={false}
                  avatarImgPath={''}
                  avatarAltText={''}
                  imagePath={card.image.url}
                  imageAltText={card.imageAltText}
                  cardTitle={card.name}
                  cardContentText={''}
                  dateOfCardText={''}
                  plusButtonBoolean={false}
                  avatarBoolean={false}
                  buttonText={card.buttonText}
                  buttonUrl={card.buttonUrl}
                  checked={false}
                  branding={'aspendental'}
                  eventType={card.eventType}
                  stepName={card.stepName}
                />
              </Grid>
            ),
          )}
      </Grid>
    </FormsMenuTemplate>
  )
}

export default PatientServicesPage

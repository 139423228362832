import React from 'react'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import { Grid, TAGButton, TAGTypography } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import { GET_ACCOUNT } from '../../constants/graphqlquery'
import { useQuery } from '@apollo/client'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import { capitalized } from '../../utils/Helper'
import { needAssistancePhoneLink } from '../../utils/Format'
export interface ICanceledPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}
const CanceledPage = (props: ICanceledPageProps) => {
  const { loading, error, data } = useQuery(GET_ACCOUNT)
  const phoneNumber = '(800) 277-3633'
  const account = data?.me?.account
  const navigate = useNavigate()
  const submitHandler = () => {
    globalThis?.sessionStorage.removeItem('isOneClickLogin')
    navigate(props.pageContent?.buttonUrl)
  }
  return (
    <GraphQLTemplate
      loading={loading}
      error={error}
      data={data}
      sessionItem="isOneClickLogin"
      errorNavigate="/patient-services/one-click-expiration"
    >
      <FormsMenuTemplate isNormalSize={props.isNormalSize ?? false}>
        <FormCredentialTitles
          titleFont="heroLarge"
          isNormalSize={props.isNormalSize ?? false}
          title={`${capitalized(account?.firstName)} ${capitalized(account?.lastName)}` ?? ''}
        >
          <TAGTypography fontStyle="h1" sx={{ display: 'inline-block', padding: '30px 0px' }}>
            {props.pageContent?.title}
          </TAGTypography>
        </FormCredentialTitles>
        <Grid container justifyContent={'center'} pb={2}>
          <TAGButton
            buttonVariant={'Secondary Default'}
            size={props.isNormalSize ? 'large' : 'small'}
            fullWidth={!props.isNormalSize}
            sx={{ margin: '5px' }}
            onClick={submitHandler}
          >
            {props.pageContent?.buttonText ?? 'Back To Patient Service'}
          </TAGButton>
        </Grid>

        {needAssistancePhoneLink(props.pageContent?.description + phoneNumber, true)}
      </FormsMenuTemplate>
    </GraphQLTemplate>
  )
}

export default CanceledPage

import React from 'react'
import { TAGRadioButtonInput, TAGTypography, Grid } from '@aspendental/design-system'
import { renderFromHelper } from './renderError'

const RJSFConditionalField = (props: any) => {
  const additionalMsg = ' (Aspen Dental practices do not accept Mass Health or Medicaid)'
  const medicaidMsg = props.formContext.medicaidMsg
  const radioItemsMapped =
    props.schema.enum &&
    props.schema.enum.map((item: any) => {
      return { inputLabel: item, inputValue: item, fieldDisabled: false }
    })
  return (
    <Grid item container direction={'column'} spacing={1}>
      <Grid item>
        <TAGTypography variant={'bodyMediumBook'} color="primary.main">
          {props.schema.title}
        </TAGTypography>
        {medicaidMsg && (
          <TAGTypography variant={'bodyMediumBook'} color="primary.main">
            {additionalMsg}
          </TAGTypography>
        )}
      </Grid>
      <Grid item>
        <TAGRadioButtonInput
          {...props}
          radioItems={radioItemsMapped}
          fullWidth
          color="tertiary.dark"
          required={props.required}
          fieldError={props.rawErrors?.length > 0}
          inputLabel={props.schema.label}
          fieldHelperText={props.rawErrors}
          value={props.formData || undefined}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange((event.target as HTMLInputElement).value)
          }}
          sx={{ color: 'text.secondary' }}
        />
        {renderFromHelper(props.rawErrors)}
      </Grid>
    </Grid>
  )
}

export default RJSFConditionalField

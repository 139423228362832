const googleApiKey = process.env.GATSBY_GOOGLE_API_KEY
export const getCoordinates = async (zipcode: string) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${googleApiKey}&components=postal_code:${zipcode}`
  if (!zipcode || zipcode === '') {
    return
  }
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Request failed!')
    }
    const data = await response.json()
    if (data.results[0]) {
      const latitude = data && data.results[0].geometry.location.lat
      const longitude = data && data.results[0].geometry.location.lng
      return { latitude, longitude }
    }
  } catch (error: any) {
    throw new Error(error.message || 'Something went wrong!')
  }
}

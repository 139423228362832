import { TAGButton, Grid } from '@aspendental/design-system'
import { TAGButtonType } from '@aspendental/design-system/lib/constants/type'
import React from 'react'
export interface ISubmitButtonProps {
  isNormalSize: boolean
  submitHandler?: (event?: any) => void
  buttonText: any
  disableButton?: boolean
  buttonVariant?: TAGButtonType
}
const SubmitButton = ({
  isNormalSize,
  submitHandler,
  buttonText,
  disableButton,
  buttonVariant = 'Primary Default',
}: ISubmitButtonProps) => {
  return (
    <Grid item marginTop={3}>
      <TAGButton
        type="submit"
        buttonVariant={buttonVariant}
        buttonSize={isNormalSize ? 'large' : 'small'}
        fullWidth={!isNormalSize}
        onClick={submitHandler}
        disabled={disableButton}
      >
        {buttonText}
      </TAGButton>
    </Grid>
  )
}

export default SubmitButton

import React from 'react'
import {
  Dialog,
  DialogTitle,
  TAGTypography,
  TAGPhoneDrawer,
  TAGButton,
  IconButton,
  TAGSvgIcon,
} from '@aspendental/design-system'
interface ICancellationModalProps {
  handleClose: () => void
  handleOpen: () => void
  open: boolean
  children: React.ReactNode
  title?: string
  isNormalSize: boolean
  buttonName: string
  isOneClick: boolean
  appointmentChangesDisabled: boolean
}

const CancellationModal = (props: ICancellationModalProps) => {
  return (
    <>
      {!props.isOneClick && !props.appointmentChangesDisabled && (
        <TAGButton
          buttonVariant={'Secondary High Contrast'}
          buttonSize={'small'}
          fullWidth={false}
          onClick={props.handleOpen}
          sx={{ margin: '8px' }}
        >
          {props.buttonName}
        </TAGButton>
      )}
      {props.isNormalSize ? (
        <Dialog
          open={props.open}
          fullWidth
          onClose={props.handleClose}
          sx={{ padding: '50px 100px', borderRadius: '8px' }}
          id="cancelDialog"
        >
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            size="large"
            sx={{
              position: 'absolute',
              right: 10,
              top: 10,
            }}
          >
            <TAGSvgIcon icon="Times" color="neutral.tertiary"></TAGSvgIcon>
          </IconButton>
          <DialogTitle>
            <TAGTypography variant={'h3'}>{props.title}</TAGTypography>
          </DialogTitle>
          {props.children}
        </Dialog>
      ) : (
        <TAGPhoneDrawer onClose={props.handleClose} onOpen={props.handleOpen} open={props.open}>
          {props.children}
        </TAGPhoneDrawer>
      )}
    </>
  )
}

export default CancellationModal

import Form, {
  AjvError,
  ErrorListProps,
  FormValidation,
  ISubmitEvent,
  ObjectFieldTemplateProps,
  Widget,
} from '@rjsf/core'
import React from 'react'
import DynamicTemplate from './FormTemplates/DynamicTemplate'
import { generateSchema } from './RJSFSchemas/Schema'
import { fields } from './UISchemas/rjsfFieldStyles'
import { generateUISchema } from './UISchemas/UISchema'
import { JSONSchema7 } from 'json-schema'
import { TAGNotification } from '@aspendental/design-system'
import { customFormats } from './FormTemplates/FormValidation'
import { camelCaseToTitleCase } from '../../utils/Helper'

export interface ISharedFormProps {
  schemaData: any
  isNormalSize: boolean
  formData?: any
  showErrorList?: boolean
  children?: React.ReactNode[] | React.ReactNode | undefined
  onSubmit?: ((e: ISubmitEvent<unknown>, nativeEvent: React.FormEvent<HTMLFormElement>) => any) | undefined
  ObjectFieldTemplate?: React.FunctionComponent<ObjectFieldTemplateProps<any>> | undefined
  validate?: ((formData: unknown, errors: FormValidation) => FormValidation) | undefined
  setForm?: (form: any) => void
  formContext?: any
  widgets?:
    | {
        [name: string]: Widget
      }
    | undefined
}

const SharedForm = (props: ISharedFormProps) => {
  function ErrorListTemplate(props: ErrorListProps) {
    const { errors } = props
    return (
      <>
        <TAGNotification elevated={false} theme={'Error'} id="NOTIFA" style={{ marginBottom: '20px' }}>
          <ul>
            {errors.map(error => (
              <li key={error.stack}>{error.message}</li>
            ))}
          </ul>
        </TAGNotification>
      </>
    )
  }

  function transformErrors(errors: AjvError[]) {
    return errors.map((error: AjvError) => {
      const result = error.property.split('.').pop()
      error.message = result ? `${camelCaseToTitleCase(result)} ${error.message}` : error.message
      if (error.name === 'format' && error.property === '.emailAddress') {
        error.message =
          'Please double-check your email address for accuracy; if issues persist, please contact your local office for assistance.'
      }
      return error
    })
  }

  return (
    <Form
      {...props}
      formContext={props.formContext}
      schema={generateSchema(props.schemaData) as JSONSchema7}
      uiSchema={generateUISchema(props.schemaData)}
      fields={fields}
      onSubmit={props.onSubmit}
      ref={form => {
        if (form) {
          props.setForm && props.setForm(form)
        }
      }}
      showErrorList={props.showErrorList}
      widgets={props.widgets}
      noHtml5Validate
      formData={props.formData}
      validate={props.validate}
      ObjectFieldTemplate={props.ObjectFieldTemplate ?? DynamicTemplate}
      customFormats={customFormats}
      transformErrors={transformErrors}
      ErrorList={ErrorListTemplate}
    >
      {props.children}
    </Form>
  )
}

export default SharedForm

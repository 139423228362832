export type FormContextType = {
  forms: { PersonalInfo: PersonalInfoContext | object; MedicalHistory: object; AuthConsent: object }
  addFormData: (_payload: { form: object; formName: string }) => void
}

export const enum FormActionTypes {
  ADD_FORM,
}

export interface FormAction {
  type: FormActionTypes
}

export interface AddFormData {
  payload: { form: object; formName: string }
  type: FormActionTypes.ADD_FORM
}

export type FormActions = AddFormData
export interface PersonalInfoContext {
  patientInformation: {
    firstName: string
    lastName: string
    email: string
    phone: string
    dateOfBirth: string
    facilityCode: string
    facilityName: string
    prefix: string
    suffix: string
    sex: string
    language: string
    preferredCommunication: string
  }
  address: {
    street: string
    city: string
    country: string
    state: string
    zipCode: string
  }
  insurance: {
    doYouHaveInsurance: 'Yes' | 'No'
    insuranceInfo: {
      subscriberFirstName: string
      subscriberLastName: string
      insurer: string
      subscriberMemberId: string
      groupId: string
    }
  }
  emergencyContact: {
    fullName: string
    phoneNumber: string
  }
  responsibleParty: {
    isPatientResponsibleParty: boolean
    responsiblePartyInfo: {
      street: string
      suffix: string
      city: string
      country: string
      dateOfBirth: string
      firstName: string
      lastName: string
      pISex: string
      state: string
      zipCode: string
    }
  }
}

export interface PatientInformationParamsInput {
  patientInformation: PatientDetailsInput
  address: PatientAddressInput
  insurance: InsuranceInput
  emergencyContact: EmergencyContactInput
  responsibleParty: ResponsiblePartyInput
}

export interface PatientDetailsInput {
  firstName: string
  lastName: string
  email: string
  phone: string
  dateOfBirth: string
  prefix: string
  suffix: string
  sex: string
  language: string
  preferredCommunication: string
}

export interface PatientAddressInput {
  street: string
  state: string
  city: string
  country: string
  zipCode: string
}

export interface InsuranceInput {
  haveInsurance: boolean
  insuranceInfo: [InsuranceInfoInput]
}

export interface InsuranceInfoInput {
  subscriberFirstName: string
  subscriberLastName: string
  insurer: string
  subscriberMemberId: string
  groupId: string
}

export interface EmergencyContactInput {
  fullName: string
  phoneNumber: string
}

export interface ResponsiblePartyInput {
  isPatientResponsibleParty: boolean | 'True' | 'False'
  responsiblePartyInfo: ResponsiblePartyDetailsInput
}

export interface ResponsiblePartyDetailsInput {
  prefix: string
  suffix: string
  firstName: string
  lastName: string
  dateOfBirth: string
  sex: string
  street: string
  state: string
  city: string
  country: string
  zipCode: string
}

export interface RescheduleVisitInput {
  visitId: string
  newFacilityCode: string
  newTimeslot: string
}

export interface CancelVisitInput {
  visitId: string
}

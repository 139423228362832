import React from 'react'
import { TAGTypography } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import SubmitButton from '../SubmitButton/SubmitButton'
export interface IConfirmPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}
const ConfirmPage = (props: IConfirmPageProps) => {
  const navigate = useNavigate()
  const submitHandler = () => {
    navigate(props.pageContent.buttonUrl)
  }
  return (
    <FormsMenuTemplate isNormalSize={props.isNormalSize}>
      <FormCredentialTitles isNormalSize={props.isNormalSize} title={props.pageContent.title}>
        <TAGTypography variant={'bodyLargeBook'} color={'text.primary'} gutterBottom>
          {props.pageContent.description}
        </TAGTypography>
      </FormCredentialTitles>
      <SubmitButton
        isNormalSize={props.isNormalSize}
        submitHandler={submitHandler}
        buttonText={props.pageContent.buttonText}
      />
    </FormsMenuTemplate>
  )
}

export default ConfirmPage

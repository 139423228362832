import React from 'react'
import { TAGTextFieldInput, Grid, useTheme } from '@aspendental/design-system'

const RJSFZipcodeField = (props: any) => {
  const disallowedCharactersRe = /[^0-9]/
  const theme = useTheme()

  function handleChange(zipCode: string) {
    const hasDisallowedCharacters = zipCode.match(disallowedCharactersRe)
    if (zipCode && !hasDisallowedCharacters) {
      return zipCode.substring(0, 5)
    }
  }
  return (
    <Grid item>
      {!props.uiSchema['ui:hidden'] && (
        <TAGTextFieldInput
          fullWidth
          required={props.required}
          placeholder={props.schema.placeholder}
          color="secondary"
          type="text"
          fieldPlaceHolder={props.schema.placeholder}
          fieldError={props.rawErrors?.length > 0}
          inputLabel={props.schema.label}
          fieldHelperText={props.rawErrors && props.rawErrors[0]}
          value={props.formData || undefined}
          onChange={event => props.onChange(handleChange(event.target.value))}
          fieldDisabled={props.disabled}
          inputProps={{
            maxLength: '5',
          }}
          sx={{
            '& .MuiFormLabel-root': {
              background: '#ffffff',
              fontFamily: theme.typography.bodyMediumBook,
              fontWeight: '300',
            },
          }}
        />
      )}
    </Grid>
  )
}

export default RJSFZipcodeField

import { gql } from '@apollo/client'

export const GET_ACCOUNT = gql`
  query getAccount {
    me {
      account {
        firstName
        lastName
        accountNumber
      }
    }
  }
`
export const GET_PI = gql`
  query getPi {
    me {
      account {
        username
        firstName
        lastName
        email
        phoneNumber
        accountNumber
      }
      appointments {
        appointmentNumber
        visitNumber
        visitDescription
        dateTimeField
        patientName
        facilityCode
        duration
      }
      patient {
        patientInformation {
          email
          phone
          dateOfBirth
          firstName
          lastName
          preferredCommunication
          language
          sex
        }
        address {
          street
          city
          country
          state
          zipCode
        }
        insurance {
          haveInsurance
          insuranceInfo {
            subscriberFirstName
            subscriberLastName
            insurer
            subscriberMemberId
            groupId
          }
        }
        emergencyContact {
          fullName
          phoneNumber
        }
        responsibleParty {
          isPatientResponsibleParty
          responsiblePartyInfo {
            dateOfBirth
            firstName
            lastName
            sex
            street
            city
            country
            state
            zipCode
          }
        }
      }
    }
  }
`

export const POST_PI = gql`
  mutation postPi (
    $patientInformation: PatientDetailsInput
    $address: PatientAddressInput
    $insurance: InsuranceInput
    $emergencyContact: EmergencyContactInput
    $responsibleParty: ResponsiblePartyInput
  ) {
    me {
      updatepatient(
        params: {
          patientInformation: $patientInformation
          address: $address
          insurance: $insurance
          emergencyContact: $emergencyContact
          responsibleParty: $responsibleParty
        }
      )
    }
  }
`

export const GET_APPOINTMENT = gql`
  query scheduledVisits {
    me {
      account {
        username
        firstName
        lastName
        email
        phoneNumber
        accountNumber
        birthDate
      }
      scheduledVisits {
        total
        items {
          canBeRescheduled
          canBeCanceled
          visitId
          visitNumber
          scheduledDateTime
          classDescription
          duration
          facility {
            name
            code
            phoneNumber
            address {
              address1
              address2
              city
              zipCode
              stateCode
            }
            location {
              latitude
              longitude
            }
          }
        }
      }
    }
  }
`
export const GET_NEARBY = gql`
  query rescheduleSlots(
    $facilityCode: String!
    $withinRadius: Decimal!
    $takeFacilities: NonNegativeInt!
    $dateFrom: Date!
    $dateTo: Date!
    $scheduledVisitId: String!
    $daysOfWeek: [DayOfWeek!]
    $partOfDay: PartOfDay
  ) {
    facility(code: $facilityCode) {
      name
      code
      phoneNumber
      location {
        latitude
        longitude
      }
      address {
        address1
        address2
        city
        zipCode
        stateCode
      }
      rescheduleSlots(
        filter: {
          dateFrom: $dateFrom
          dateTo: $dateTo
          scheduledVisitId: $scheduledVisitId
          daysOfWeek: $daysOfWeek
          partOfDay: $partOfDay
        }
      ) {
        items {
          timeslot
          timeslotUid
        }
        total
      }
      nearbyRescheduleFacilities(
        filter: {
          withinRadius: $withinRadius
          dateFrom: $dateFrom
          dateTo: $dateTo
          scheduledVisitId: $scheduledVisitId
          daysOfWeek: $daysOfWeek
          partOfDay: $partOfDay
        }
        take: $takeFacilities
      ) {
        items {
          distanceInMiles
          facility {
            name
            phoneNumber
            location {
              latitude
              longitude
            }
            code
            address {
              address1
              address2
              city
              zipCode
              stateCode
            }
            rescheduleSlots(
              filter: {
                dateFrom: $dateFrom
                dateTo: $dateTo
                scheduledVisitId: $scheduledVisitId
                daysOfWeek: $daysOfWeek
                partOfDay: $partOfDay
              }
            ) {
              items {
                timeslot
                timeslotUid
              }
              total
            }
          }
        }
      }
    }
  }
`

export const GET_FACILITY = gql`
  query scheduledVisits {
    me {
      scheduledVisits {
        items {
          canBeRescheduled
          canBeCanceled
          visitId
          facility {
            code
            name
            phoneNumber
            address {
              address1
              address2
              city
              zipCode
              stateCode
            }
          }
        }
      }
    }
  }
`
export const NEABY_LAT_LONG_SEARCH = gql`
  query filterLocation($longitude: Decimal!, $latitude: Decimal!) {
    nearbyFacilities(filter: { longitude: $longitude, latitude: $latitude, withinRadius: 10 }) {
      items {
        distanceInMiles
        facility {
          code
          name
          phoneNumber
          address {
            address1
            address2
            city
            zipCode
            stateCode
          }
        }
      }
    }
  }
`
export const RESCHEDULE_VISIT = gql`
  mutation rescheduleVisit($input: RescheduleVisitInput!) {
    me {
      rescheduleVisit(input: $input) {
        rescheduledVisit {
          visitId
          timeslot
        }
        errors {
          code: __typename
          ... on Error {
            message
          }
          ... on NonExistingVisitError {
            nonExistingVisitId
          }
        }
      }
    }
  }
`

export const CANCEL_VISIT = gql`
  mutation cancelVisit($input: CancelVisitInput!) {
    me {
      cancelVisit(input: $input) {
        canceledVisit {
          visitId
        }
        errors {
          code: __typename
          ... on Error {
            message
          }
          ... on NonExistingVisitError {
            message
          }
        }
      }
    }
  }
`
export const GET_PATIENT_INFO = gql`
  query patientInformation {
    me {
      patient {
        patientInformation {
          email
          phone
          dateOfBirth
          firstName
          lastName
          preferredCommunication
          language
          sex
        }
      }
    }
  }
`

export const POST_CONFIRM_APPT = gql`
  mutation confirmVisit($input: ConfirmVisitInput!) {
    confirmVisit(input: $input) {
      confirmedVisit {
        visitId
        scheduledDateTime
        facility {
          name
          phoneNumber
          address {
            address1
            zipCode
            stateCode
          }
        }
      }
    }
  }
`
export const POST_CONFIRM_APPT_BY_APPOINTMENT_ID = gql`
  mutation confirmVisit($input: ConfirmPatientVisitInput!) {
    me {
      confirmVisit(input: $input) {
        result {
          accountNumber
          visitId
          scheduledDateTime
          facility {
            code
            name
            phoneNumber
            address {
              address1
              zipCode
              stateCode
            }
          }
        }
        errors {
          __typename
          ... on NonExistingVisitError {
            message
          }
        }
      }
    }
  }
`
export const POST_CONFIRM_APPT_ERROR = gql`
  mutation confirmVisitError($errorInput: ConfirmVisitInput!) {
    confirmVisit(input: $errorInput) {
      confirmedVisit {
        visitId
      }
    }
  }
`

export const GET_APPOINTMENT_BY_APPOINTMENT_ID = gql`
  query scheduledVisit($visitId: String!) {
    me {
      account {
        username
        firstName
        lastName
        email
        phoneNumber
        accountNumber
        birthDate
      }
      scheduledVisit(visitId: $visitId) {
        canBeRescheduled
        canBeCanceled
        visitId
        visitNumber
        scheduledDateTime
        classDescription
        duration
        facility {
          name
          code
          phoneNumber
          address {
            address1
            address2
            city
            zipCode
            stateCode
          }
          location {
            latitude
            longitude
          }
        }
      }
    }
  }
`

export const GET_ONE_CLICK_FACILITY = gql`
  query scheduledVisit($visitId: String!) {
    me {
      scheduledVisit(visitId: $visitId) {
        canBeRescheduled
        canBeCanceled
        visitId
        facility {
          code
          name
          phoneNumber
          address {
            address1
            address2
            city
            zipCode
            stateCode
          }
        }
      }
    }
  }
`

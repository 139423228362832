import React from 'react'
import { useQuery } from '@apollo/client'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import { GET_APPOINTMENT_BY_APPOINTMENT_ID } from '../../constants/graphqlquery'
import { IOneClickProps } from '../OneClickPrePage/OneClickPrePage'
import AppointmentPage from '../AppointmentPage/AppointmentPage'
import ReschedulePage from '../ReschedulePage/ReschedulePage'

type IOneClickRescheduleProps = IOneClickProps
const OneClickReschedule = (props: IOneClickRescheduleProps) => {
  const { loading, error, data } = useQuery(GET_APPOINTMENT_BY_APPOINTMENT_ID, {
    variables: { visitId: props.visitId },
  })
  const rescheduleVisit =
    data?.me?.scheduledVisit?.canBeRescheduled ||
    window.location.href.match('/patient-services/reschedule-appointment/success')
  return (
    <GraphQLTemplate
      loading={loading}
      error={error}
      data={data}
      sessionItem="isOneClickLogin"
      errorNavigate="/patient-services/one-click-expiration"
    >
      {rescheduleVisit ? (
        <ReschedulePage
          isNormalSize={props.isNormalSize}
          sessionItem="isOneClickLogin"
          errorNavigate="/patient-services/one-click-expiration"
          pageContent={props.pageContent}
          visitId={props.visitId}
        />
      ) : (
        <AppointmentPage
          isNormalSize={props.isNormalSize}
          pageContent={props.pageContent}
          sessionItem="isOneClickLogin"
          errorNavigate="/patient-services/one-click-expiration"
          visitId={props.visitId}
        />
      )}
    </GraphQLTemplate>
  )
}

export default OneClickReschedule

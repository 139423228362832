import React from 'react'
import { useQuery } from '@apollo/client'
import { Card, Box, Container, HeroV2 } from '@aspendental/design-system'
import { GET_APPOINTMENT, GET_APPOINTMENT_BY_APPOINTMENT_ID } from '../../constants/graphqlquery'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import AppointmentCard from './AppointmentCard'
import LocationCard from './LocationCard'
import { isAfter } from 'date-fns'
import { SessionType } from '../../constants/type'
import { useNavigate } from '@reach/router'
import { capitalized } from '../../utils/Helper'

export interface IAppointmentPageProps {
  isNormalSize?: boolean
  path?: string
  pageContent?: any
  sessionItem?: SessionType
  errorNavigate?: string
  visitId?: string
  initModal?: boolean
}

const AppointmentPage = (props: IAppointmentPageProps) => {
  const isOneClickLogin = globalThis?.sessionStorage.getItem('isOneClickLogin') === 'true'
  const navigate = useNavigate()
  // Calling GraphQL for getting User Current Appointment info and facility info
  const { loading, error, data } = isOneClickLogin
    ? useQuery(GET_APPOINTMENT_BY_APPOINTMENT_ID, { variables: { visitId: props.visitId } })
    : useQuery(GET_APPOINTMENT)
  const oneClickAppointment = data?.me?.scheduledVisit
  const scheduledAppointments = data?.me?.scheduledVisits
  const account = data?.me?.account
  const errorHero = props.pageContent?.pages[12].contents[3]

  React.useEffect(() => {
    if (isOneClickLogin && error) {
      navigate('/patient-services/one-click-expiration')
    }
  }, [])
  const content = props.pageContent.pages[12]
  const findRightItem =
    !isOneClickLogin &&
    scheduledAppointments?.items?.length &&
    scheduledAppointments?.items.findIndex((ele: any) => isAfter(new Date(ele.scheduledDateTime), new Date()))

  const appointmentCardInfo = isOneClickLogin
    ? { ...oneClickAppointment, ...account }
    : { ...scheduledAppointments?.items[findRightItem], ...account }

  const appointmentLocationInfo = isOneClickLogin
    ? { facility: oneClickAppointment?.facility }
    : { facility: scheduledAppointments?.items[findRightItem]?.facility }

  const title = `Welcome, ${capitalized(account?.firstName)} ${capitalized(account?.lastName)}`
  const homeOffice = scheduledAppointments?.items[0]?.facility
  const oneClickHomeOffice = oneClickAppointment?.facility

  return (
    <>
      {!oneClickAppointment && !scheduledAppointments && !loading ? (
        <Container>
          <HeroV2
            branding={'aspendental'}
            darkOverlay={errorHero?.darkOverlay}
            focalPointImage={errorHero?.focalPointImage}
            contentAlign={errorHero?.contentAlign}
            contentPosition={errorHero?.contentPosition}
            bodySize={errorHero?.bodySize}
            bodyHero={errorHero?.body}
            headingHero={errorHero?.heading}
            headingSize={errorHero?.headingSize}
            contentPositionSmallScreen={errorHero?.contentSmallScreen}
            templateWidth={errorHero?.templateWidth}
            video={errorHero?.video}
          />
        </Container>
      ) : (
        <GraphQLTemplate
          loading={loading}
          error={error}
          data={data}
          sessionItem={props.sessionItem}
          errorNavigate={props.errorNavigate}
        >
          <FormsMenuTemplate isNormalSize={props.isNormalSize ?? true}>
            <FormCredentialTitles
              isNormalSize={props.isNormalSize ?? true}
              titleFont="heroLarge"
              title={title}
            ></FormCredentialTitles>
            <Box
              sx={{
                display: 'flex',
                flexDirection: props.isNormalSize ? 'row' : 'column',
                padding: '8px',
                boxSizing: 'border-box',
                justifyContent: 'center',
              }}
            >
              <>
                <Card
                  sx={{
                    backgroundColor: 'backgrounds.dark',
                    width: props.isNormalSize ? (homeOffice ? '66%' : '86%') : '100%',
                    padding: '20px',
                    marginY: props.isNormalSize ? '0px' : '8px',
                    marginX: props.isNormalSize ? '8px' : '0px',
                    boxSizing: 'border-box',
                  }}
                >
                  <AppointmentCard
                    apptInfo={appointmentCardInfo}
                    isNormalSize={props.isNormalSize ?? true}
                    contentCard={content.contents[0].cards[0]}
                    initModal={props.initModal ?? false}
                    modalContent={content.contents}
                    homeOfficePhone={isOneClickLogin ? oneClickHomeOffice?.phoneNumber : homeOffice?.phoneNumber}
                  />
                </Card>
                {(homeOffice || oneClickHomeOffice) && (
                  <Card
                    variant="outlined"
                    sx={{
                      width: props.isNormalSize ? '33%' : '100%',
                      marginY: props.isNormalSize ? '0px' : '8px',
                      marginX: props.isNormalSize ? '8px' : '0px',
                      padding: '20px',
                      boxSizing: 'border-box',
                    }}
                  >
                    <LocationCard
                      apptInfo={appointmentLocationInfo}
                      contentCard={content.contents[0].cards[1]}
                      homeOffice={isOneClickLogin ? oneClickHomeOffice : homeOffice}
                    />
                  </Card>
                )}
              </>
            </Box>
          </FormsMenuTemplate>
        </GraphQLTemplate>
      )}
    </>
  )
}

export default AppointmentPage

const esc = encodeURIComponent

class ApiClient {
  enterpriseUrl: string
  apiName: string

  constructor() {
    // private vars with public setters
    this.enterpriseUrl = ''
    this.apiName = ''
  }

  setApiName(apiName: string) {
    this.apiName = apiName
  }

  setEnterpriseUrl(hostUrl: string) {
    this.enterpriseUrl = hostUrl
  }

  async _makeCall(path: string, config?: { headers?: object; method: string; body?: string }) {
    const URL = this.enterpriseUrl + path
    try {
      const resp = await fetch(URL, {
        ...config,
        headers: { ...config?.headers, 'x-client-application': this.apiName },
      })
      if (!resp.ok) {
        return resp
      }
      const data = await resp.text()
      const response = data === '' ? true : JSON.parse(data)
      return response
    } catch (error) {
      return error
    }
  }

  get(path: string, params?: object) {
    const keys = params ? (Object.keys(params) as Array<keyof typeof params>) : []
    const query =
      params &&
      keys
        .map(k =>
          Array.isArray(params[k])
            ? `${esc(k)}=${(params[k] as string[]).join(`&${esc(k)}=`)}`
            : `${esc(k)}=${esc(params[k] as string)}`,
        )
        .join('&')
    return this._makeCall(path + '?' + (query || ''))
  }

  validateToken(path: string, token?: string) {
    const config = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
        Authorization: `Bearer ${token}`,
        withCredentials: true,
      },
      redirect: 'follow',
      credentials: 'include',
    }
    return this._makeCall(path, config)
  }

  post(path: string, data: object) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Data-Type': 'json',
      },
      body: JSON.stringify(data),
    }
    return this._makeCall(path, config)
  }

  loginpost(path: string, data: object) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
      },
      redirect: 'follow',
      credentials: 'include',
      body: JSON.stringify(data),
    }
    return this._makeCall(path, config)
  }

  finishemailpost(path: string, data: object, token: string) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
        Authorization: token,
        withCredentials: true,
      },
      redirect: 'follow',
      credentials: 'include',
      body: JSON.stringify(data),
    }
    return this._makeCall(path, config)
  }

  authpost(path: string, data: string) {
    const config = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    }
    return this._makeCall(path, config)
  }

  put(path: string, data: object) {
    const config = {
      method: 'PUT',
      body: JSON.stringify(data),
    }
    return this._makeCall(path, config)
  }

  delete(path: string, data: object) {
    const config = {
      method: 'DELETE',
      body: JSON.stringify(data),
    }
    return this._makeCall(path, config)
  }
}

const apiClient = new ApiClient()

export { apiClient }

import React from 'react'
import { GET_APPOINTMENT_BY_APPOINTMENT_ID } from '../../constants/graphqlquery'
import { useQuery } from '@apollo/client'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import AppointmentPage from '../AppointmentPage/AppointmentPage'
import { IOneClickProps } from '../OneClickPrePage/OneClickPrePage'

type IOneClickCancelPageProps = IOneClickProps
const OneClickCancelPage = (props: IOneClickCancelPageProps) => {
  const { loading, error, data } = useQuery(GET_APPOINTMENT_BY_APPOINTMENT_ID, {
    variables: { visitId: props.visitId },
  })
  return (
    <GraphQLTemplate
      loading={loading}
      error={error}
      data={data}
      sessionItem="isOneClickLogin"
      errorNavigate="/patient-services/one-click-expiration"
    >
      <AppointmentPage
        isNormalSize={props.isNormalSize}
        pageContent={props.pageContent}
        sessionItem="isOneClickLogin"
        errorNavigate="/patient-services/one-click-expiration"
        visitId={props.visitId}
        initModal={true}
      />
    </GraphQLTemplate>
  )
}

export default OneClickCancelPage

import React, { FC } from 'react'
import { TAGTypography } from '@aspendental/design-system'

export const FourOhFour: FC = () => {
  return (
    <h1>
      <TAGTypography variant="overline">404</TAGTypography> - Not Found
    </h1>
  )
}

export default FourOhFour

import { toCamelCase } from '../../../utils/Helper'

export const getRequiredList = (object: object) => {
  const result: string[] = []
  object &&
    Object.keys(object).length > 0 &&
    Object.keys(object).forEach(key => {
      if (object[key as keyof typeof object]['required']) {
        result.push(toCamelCase(object[key as keyof typeof object]['title']))
      }
    })
  return result
}

export const generateProperty = (properties: any) => {
  if (!properties) {
    return {}
  }
  const formProperties: any = {}
  properties.forEach((formField: any) => {
    // format item object list for json schema
    const itemList =
      formField.field === 'RJSFSelectField'
        ? formField.items &&
          formField.items.length > 0 &&
          formField.items.map((item: any) => {
            return { title: item }
          })
        : formField.items
    formProperties[toCamelCase(formField.title)] = {
      title: formField.title,
      type: formField.type,
      ...(formField.default ? { default: formField.default } : {}),
      ...(formField.items ? { items: { type: 'string', enum: itemList } } : {}),
      ...(formField.enum ? { enum: formField.enum } : {}),
      ...(formField.minLength ? { minLength: formField.minLength } : {}),
      ...(formField.label ? { label: formField.label } : {}),
      ...(formField.format ? { format: formField.format } : {}),
      ...(formField.field === 'TimePicker' ? { timeOfDay: 'ALL' } : {}),
      ...(formField.field === 'TimePicker'
        ? { weekDays: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'] }
        : {}),
      ...(formField.field === 'ButtonGroupField' ? { confirmTime: '' } : {}),
    }
  })
  return formProperties
}

export const generateDependencies = (properties: any) => {
  if (!properties) {
    return {}
  }
  const formDependencies: any = {}
  const tempProperties: any = {}
  const dependencyDefs: any = {}
  properties.forEach((formField: any) => {
    if (formField.dependency) {
      tempProperties[toCamelCase(formField.title)] = {
        ...(formField.dependency.condition
          ? {
              enum: [
                formField.dependency.condition === 'true' || formField.dependency.condition === 'false'
                  ? formField.dependency.condition === 'true'
                  : formField.dependency.condition,
              ],
            }
          : {}),
      }
      dependencyDefs[formField.dependency.dependencyDef] = { $ref: formField.dependency.showFieldPath }
      formDependencies[toCamelCase(formField.title)] = {
        oneOf: [
          {
            properties: {
              ...tempProperties,
              ...dependencyDefs,
            },
          },
        ],
      }
    }
  })
  return formDependencies
}

export const generateDefinites = (definitions: any) => {
  if (!definitions) {
    return {}
  }
  const formDefinitions: any = {}
  definitions.map((definition: any) => {
    const properties = generateProperty(definition.formFields)
    const dependencies = generateDependencies(definition.formFields)
    formDefinitions[toCamelCase(definition.title)] = {
      title: definition.title,
      description: definition.description ?? '',
      label: definition.label,
      displayDefinition: definition.displayDefinition,
      type: 'object',
      titleFont: definition.titleFont,
      descriptionFont: definition?.descriptionFont,
      required: getRequiredList(definition.formFields),
      properties: {
        ...properties,
      },
      dependencies: {
        ...dependencies,
      },
    }
  })
  return formDefinitions
}

export const generateSchema = (form: any) => {
  if (!form) {
    return {}
  }
  const properties = generateProperty(form.formFields)
  const definition = generateDefinites(form.definitions ? form.definitions : [])
  const referDefinitions: any = {}
  Object.keys(definition).map((def: any) => {
    if (definition[def]['displayDefinition']) {
      referDefinitions[toCamelCase(def)] = { $ref: `#/definitions/${def}` }
    }
  })
  const schema = {
    title: form.title,
    description: form.description,
    label: form.label,
    type: 'object',
    titleFont: form.titleFont,
    descriptionFont: form?.descriptionFont,
    titleColor: form.titleColor,
    descriptionColor: form.descriptionColor,
    definitions: {
      ...definition,
    },
    required: getRequiredList(form.formFields),
    properties: {
      ...referDefinitions,
      ...properties,
    },
  }
  return schema
}

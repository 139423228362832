import React from 'react'
import UserContext from './user-context'
import { UserActionTypes } from './user.actions'
import { userReducer, defaultUserState } from './userReducer'

interface IUserProvider {
  children: React.ReactNode
}
const UserProvider = (props: IUserProvider) => {
  const [userState, dispatchUserAction] = React.useReducer(userReducer, defaultUserState)
  const addingUserData = (user: object) => {
    dispatchUserAction({ type: UserActionTypes.ADD_USER, user: user })
  }
  const addingRescheduleForm = (rescheduleForm: object) => {
    dispatchUserAction({ type: UserActionTypes.ADD_RESCHEDULE_FORM, rescheduleForm: rescheduleForm })
  }
  const addingVisitInfo = (visitInfo: object) => {
    dispatchUserAction({ type: UserActionTypes.ADD_VISIT_INFO, visitInfo: visitInfo })
  }
  const userContext = {
    user: userState.user,
    rescheduleForm: userState.rescheduleForm,
    visitInfo: userState.visitInfo,
    addVisitInfo: addingVisitInfo,
    addUserData: addingUserData,
    addRescheduleForm: addingRescheduleForm,
  }
  return <UserContext.Provider value={userContext}>{props.children}</UserContext.Provider>
}
export default UserProvider

import { toCamelCase } from '../../../utils/Helper'

export const generateUIProperty = (uiData: any) => {
  if (!uiData) {
    return {}
  }
  const uiStyleProperties: any = {}
  uiData.forEach((element: any) => {
    uiStyleProperties[toCamelCase(element.title)] = {
      'ui:phoneSize': element.phoneSize,
      'ui:createNewLine': element.createNewLine,
      'ui:divider': element.divider,
      'ui:size': element.size,
      'ui:field': element.field,
      ...(element.widget ? { 'ui:widget': element.widget } : {}),
      'ui:options': {
        label: element.needOuterLabel,
      },
    }
  })
  return uiStyleProperties
}

export const generateDependencyPropertyUIschema = (uiData: any, dependencyUI: any) => {
  if (!uiData) {
    return {}
  }
  const uiStyleDependencyProperties: any = {}
  uiData.forEach((element: any) => {
    if (element.dependency) {
      uiStyleDependencyProperties[element.dependency.dependencyDef] = dependencyUI[element.dependency.dependencyDef]
    }
    uiStyleDependencyProperties[toCamelCase(element.title)] = {
      ...uiStyleDependencyProperties[toCamelCase(element.title)],
      'ui:phoneSize': element.phoneSize,
      'ui:createNewLine': element.createNewLine,
      'ui:divider': element.divider,
      'ui:size': element.size,
      'ui:field': element.field,
      ...(element.widget ? { 'ui:widget': element.widget } : {}),
      'ui:options': {
        label: element.needOuterLabel,
      },
    }
  })
  return uiStyleDependencyProperties
}

export const generateDefinitionUISchema = (uiDefinitionData: any) => {
  if (!uiDefinitionData) {
    return {}
  }
  const uiDefinitionProperties: any = {}
  uiDefinitionData.map((definition: any) => {
    uiDefinitionProperties[toCamelCase(definition.title)] = {
      ...generateUIProperty(definition.formFields),
      ...generateDependencyPropertyUIschema(definition.formFields, uiDefinitionProperties),
      'ui:phoneSize': definition.phoneSize,
      'ui:createNewLine': definition.createNewLine,
      'ui:divider': definition.divider,
      'ui:size': definition.size,
      'ui:field': definition.field,
    }
  })

  return uiDefinitionProperties
}

export const generateUISchema = (uiSchemaData: any) => {
  const uiSchema = {
    ...generateUIProperty(uiSchemaData.formFields),
    ...generateDefinitionUISchema(uiSchemaData.definitions ? uiSchemaData.definitions : []),
    'ui:hideError': true,
  }
  return uiSchema
}

import React from 'react'
import { TAGTypography, TAGSwitchInput, Box } from '@aspendental/design-system'
interface IShow3DayProps {
  show3Days: boolean
  handleShow3Days: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const Show3DaySwitch = (props: IShow3DayProps) => {
  return (
    <>
      <Box sx={{ minWidth: '40px' }} id="3daySwitch">
        <TAGSwitchInput
          className="3daySwitchButton"
          color="secondary"
          switchValue={props.show3Days}
          checked={props.show3Days}
          onChange={props.handleShow3Days}
          fieldDisabled={props.disabled || false}
        />
      </Box>
      <TAGTypography sx={{ paddingLeft: { xs: '15px', sm: '0px' } }}>
        Show nearby appointments within the next 3 days
      </TAGTypography>
    </>
  )
}

export default Show3DaySwitch

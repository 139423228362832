module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T6NM8JLH","includeInDevelopment":true,"enableWebVitalsTracking":true,"defaultDataLayer":{"type":"object","value":{"location":"","error_type":"","error_details":"","error_status":"no","platform":"gatsby","location_status":"","experience":"Portal","brand":"Aspen Dental","appointment_facility_id":"","appointment_facility_name":"","site_section":"aspendental-patient-services","last_build":"2024-09-03T15:52:34.505Z","app_version":"0.0.1-0","design_system_version":"1.126.0"}},"routeChangeEventName":"page_view","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AspenDental Patient Services - Micro Frontend","short_name":"aspendental-patient-services","start_url":"/patient-services","background_color":"#ffffff","theme_color":"#002954","display":"standalone","orientation":"any","icon":"src/images/aspendental-icon.png","site_section":"aspendental-patient-services","app_version":"0.0.1-0","design_system_version":"1.126.0","last_build":"2024-09-03T15:52:34.505Z","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30ff9c70fc1bb0e28a6027d3ecd0a61b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

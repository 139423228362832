import React from 'react'
import {
  CardContent,
  TAGTypography,
  CardActions,
  TAGButton,
  TAGLink,
  dataLayerConfig,
} from '@aspendental/design-system'
import { formatPhoneNumber } from '../../utils/Helper'

interface ILocationCardProps {
  contentCard: any
  homeOffice: {
    name: string
    code: string
    phoneNumber: string
    address: {
      address1: string
      address2: string
      city: string
      zipCode: string
      stateCode: string
    }
    location: {
      latitude: number
      longitude: number
    }
  }
  apptInfo: {
    facility: {
      name: string
      code: string
      phoneNumber: string
      address: {
        address1: string
        address2: string
        city: string
        zipCode: string
        stateCode: string
      }
      location: {
        latitude: number
        longitude: number
      }
    }
  }
}

const LocationCard = (props: ILocationCardProps) => {
  const phoneNumber = `${formatPhoneNumber(props.apptInfo?.facility?.phoneNumber || props.homeOffice?.phoneNumber)}`
  const findLocaiontOnMap = () => {
    const longitude = props.apptInfo.facility.location.longitude || props.homeOffice?.location.longitude
    const latitude = props.apptInfo.facility.location.latitude || props.homeOffice?.location.latitude

    window.open('https://maps.google.com?q=' + latitude + ',' + longitude)

    dataLayerConfig({
      event: 'patient_services_dashboard',
      step_name: 'dashboard_action',
      link_text: props.contentCard.buttonText,
      link_url: `https://maps.google.com?q=${latitude},${longitude}`,
    })
  }
  return (
    <React.Fragment>
      <CardContent sx={{ textAlign: 'start', color: '#002855' }}>
        <TAGTypography fontStyle="overline" gutterBottom>
          {props.contentCard.title}
        </TAGTypography>
        <br />
        <br />
        <TAGTypography variant="h4" gutterBottom>
          {props.apptInfo?.facility?.name || props.homeOffice?.name}
        </TAGTypography>
        <TAGTypography variant="bodyLargeBook" gutterBottom>
          {`${props.apptInfo?.facility?.address.address1 || props.homeOffice?.address.address1} ${
            props.apptInfo?.facility?.address.address2 || props.homeOffice?.address.address2
          }`}
          <br />
          {`${props.apptInfo?.facility?.address.city || props.homeOffice?.address.city}, ${
            props.apptInfo?.facility?.address.stateCode || props.homeOffice?.address.stateCode
          } ${props.apptInfo?.facility?.address.zipCode || props.homeOffice?.address.zipCode}`}
        </TAGTypography>
        <br />
        <TAGLink
          underline="hover"
          color="secondary"
          sx={{
            cursor: 'pointer',
          }}
          variant={'bodyLargeBold'}
          href={`tel:+1${props.apptInfo?.facility?.phoneNumber || props.homeOffice?.phoneNumber}`}
          eventType="patient_services_dashboard"
          stepName="dashboard_action"
          linkText={phoneNumber}
        >
          {phoneNumber}
        </TAGLink>
      </CardContent>
      <CardActions>
        <TAGButton
          buttonVariant={'Primary Default'}
          buttonSize={'small'}
          fullWidth={false}
          sx={{ margin: '8px' }}
          onClick={findLocaiontOnMap}
        >
          {props.contentCard.buttonText}
        </TAGButton>
      </CardActions>
    </React.Fragment>
  )
}

export default LocationCard

import { ApolloClient, ApolloProvider, InMemoryCache, useMutation } from '@apollo/client'
import { TAGTypography, Grid, HeroV2, Container } from '@aspendental/design-system'
import Bugsnag from '@bugsnag/js'
import React from 'react'
import { format } from 'date-fns'
import { POST_CONFIRM_APPT } from '../../constants/graphqlquery'
import { formatPhoneNumber, timeWithoutTimeZone } from '../../utils/Helper'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import RescheduleTemplate from '../ReschedulePage/RescheduleTemplate'
import RescheduleReviewCard, { IRescheduleReviewCardProps } from '../RescheduleReviewPage/RescheduleReviewCard'
import { getConfig } from '../../utils/getConfig'
export interface IOneClickConfirmPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}
const OneClickConfirmPage = (props: IOneClickConfirmPageProps) => {
  const [gqlError, setgqlError] = React.useState<any>(null)

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const scheduledVisitId = urlParams.get('apptNum') ?? ''
  const facilityCode = urlParams.get('faccode')?.substring(0, 4) ?? ''
  const digiactNumber = urlParams.get('digi') ?? ''
  const isNotValidParams = !scheduledVisitId || !facilityCode || !digiactNumber
  const config = getConfig()
  const client = new ApolloClient({
    uri: `${config.ENTERPRISE_API_URL}bbff/websitegraphql/graphql/`,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })
  const [confirmAppt, { loading, error, data }] =
    useMutation<{ confirmVisit: { confirmedVisit: any; errors: any } }>(POST_CONFIRM_APPT)
  const appointmentInfo = data?.confirmVisit?.confirmedVisit
  const addressLine2 = `${appointmentInfo?.facility?.name}  ${appointmentInfo?.facility?.address.zipCode}`
  const cardData: IRescheduleReviewCardProps[] = [
    {
      title: 'Office Location',
      subtitles: [
        appointmentInfo?.facility?.name,
        appointmentInfo?.facility?.address?.address1 + ' ' + addressLine2 || '',
        formatPhoneNumber(appointmentInfo?.facility?.phoneNumber) || '',
      ],
      icon: 'AspenOffice',
    },
    {
      title: 'Date & Time',
      subtitles: [
        appointmentInfo?.scheduledDateTime && format(timeWithoutTimeZone(appointmentInfo?.scheduledDateTime), 'PPPP'),
        appointmentInfo?.scheduledDateTime && format(timeWithoutTimeZone(appointmentInfo?.scheduledDateTime), 'p'),
      ],
      icon: 'CalendarDateTime',
    },
  ]

  React.useEffect(() => {
    const confirmAppointment = async () => {
      try {
        if (scheduledVisitId && facilityCode) {
          await confirmAppt({
            variables: {
              input: {
                scheduledVisitId: scheduledVisitId,
                facilityCode: facilityCode,
                digiactNumber: digiactNumber,
              },
            },
          })
          if (error) {
            setgqlError(error)
            Bugsnag.notify(JSON.stringify(error))
          }
        }
      } catch (err: any) {
        setgqlError(err)
        Bugsnag.notify(JSON.stringify(err))
      }
    }
    confirmAppointment()
  }, [])

  return (
    <>
      <ApolloProvider client={client}>
        {error || gqlError || isNotValidParams ? (
          <Container>
            <HeroV2
              branding={'aspendental'}
              darkOverlay={props.pageContent.contents[0].darkOverlay}
              focalPointImage={props.pageContent.contents[0].focalPointImage}
              contentAlign={props.pageContent.contents[0].contentAlign}
              contentPosition={props.pageContent.contents[0].contentPosition}
              bodySize={props.pageContent.contents[0].bodySize}
              bodyHero={props.pageContent.contents[0].body}
              headingHero={props.pageContent.contents[0].heading}
              headingSize={props.pageContent.contents[0].headingSize}
              contentPositionSmallScreen={props.pageContent.contents[0].contentSmallScreen}
              templateWidth={props.pageContent.contents[0].templateWidth}
              video={props.pageContent.contents[0].video}
            />
          </Container>
        ) : (
          <GraphQLTemplate loading={loading} error={error} data={data}>
            <RescheduleTemplate
              enfant={
                <>
                  <TAGTypography
                    variant="h3"
                    sx={{ fontSize: props.isNormalSize && '25px', padding: '20px' }}
                    color="text.primary"
                    id={'pageTitle'}
                  >
                    {`${props.pageContent.title}!`}
                  </TAGTypography>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: props.isNormalSize ? '0px 16px 16px 5px' : '20px', overflow: 'auto' }}
                  >
                    {cardData.map(card => (
                      <Grid item xs={12} sm={6} key={card.title}>
                        <RescheduleReviewCard title={card.title} subtitles={card.subtitles} icon={card.icon} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              }
              path={''}
              isNormalSize={props.isNormalSize}
              headingText={''}
              needAssistanceText={'Need further assistance? Call '}
              phonNumber={appointmentInfo?.facility?.phoneNumber}
            ></RescheduleTemplate>
          </GraphQLTemplate>
        )}
      </ApolloProvider>
    </>
  )
}

export default OneClickConfirmPage

import React from 'react'
import { DialogActions, Grid, TAGModal, TAGTextButton, TAGTypography, dataLayerConfig } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import { format } from 'date-fns'
import { TAGModalTheme } from '@aspendental/design-system/lib/components/elements/TAGModal/TAGModal.type'
import UserContext from '../../contexts/userContext/user-context'
import RescheduleReviewCard, { IRescheduleReviewCardProps } from './RescheduleReviewCard'
import { formatPhoneNumber, timeWithoutTimeZone } from '../../utils/Helper'
import { useQuery } from '@apollo/client'
import { GET_PATIENT_INFO } from '../../constants/graphqlquery'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import { SessionType } from '../../constants/type'

interface IDTConfirmationPageProps {
  isNormalSize: boolean
  pageContent?: any
  sessionItem?: SessionType
  errorNavigate?: string
}

const RescheduleReviewPage = (props: IDTConfirmationPageProps) => {
  const isOneClickLogin = globalThis?.sessionStorage.getItem('isOneClickLogin') === 'true'
  const userCtx = React.useContext(UserContext)
  const [dialogInfo] = React.useState<{
    bodyText: string
    headlineText: string
    primaryButtonText: string
    secondaryButtonText: string
    theme: TAGModalTheme
  } | null>(null)
  const navigate = useNavigate()
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false)
  const appointment = userCtx.rescheduleForm.appointmentTimecode?.split('$')
  const { loading, error, data } = useQuery(GET_PATIENT_INFO)

  const params = window.location.search

  React.useEffect(() => {
    // Virtual page views "needed" for SPA-related pages. includes entire rescheduling flow
    dataLayerConfig({
      event: 'virtualPageview',
      virtualPageURL: 'https://www.aspendental.com/patient-services/review',
      virtualPageTitle: 'Patient Services - Review'
    });

    if (
      !appointment ||
      !userCtx.rescheduleForm ||
      JSON.stringify(userCtx.rescheduleForm) === '{}' ||
      !userCtx.rescheduleForm?.location
    ) {
      isOneClickLogin ? navigate(`/patient-services/`) : navigate('/patient-services/appointment')
    }
  }, [])
  const { firstName, lastName, dateOfBirth, email, mobilePhoneNumber } = data?.me?.patient?.patientInformation ?? {}
  const { location, addressLine1, addressLine2, phoneNumber } = userCtx.rescheduleForm?.location ?? {}
  const appointmentDate = appointment && format(timeWithoutTimeZone(appointment[1].substring(0, 29)), 'PPPP') //'Wednesday, November 17, 2021',
  const appointmentTime = appointment && format(timeWithoutTimeZone(appointment[1].substring(0, 29)), 'p')
  const cardData: IRescheduleReviewCardProps[] = [
    {
      subtitles: [firstName + ' ' + lastName, dateOfBirth, email, mobilePhoneNumber],
      icon: 'Yourself',
    },
    {
      subtitles: [location, addressLine1 + ' ' + addressLine2 || '', formatPhoneNumber(phoneNumber) || ''],
      icon: 'AspenOffice',
    },
    {
      editLinkHandler: () => {
        isOneClickLogin
          ? navigate(`/patient-services/reschedule-appointment/${params}`)
          : navigate('/patient-services/reschedule')
      },
      subtitles: [appointmentDate, appointmentTime],
      icon: 'CalendarDateTime',
    },
  ]
  const handleWarningDialogClose = () => {
    setOpenWarningDialog(false)
  }

  return (
    <>
      <GraphQLTemplate
        loading={loading}
        error={error}
        data={data}
        sessionItem={props.sessionItem}
        errorNavigate={props.errorNavigate}
      >
        <TAGTypography
          variant="h3"
          sx={{ fontSize: props.isNormalSize && '25px', padding: '20px' }}
          color="text.primary"
          id={'pageTitle'}
        >
          {props.pageContent.reviewStepName}
        </TAGTypography>
        <Grid
          container
          spacing={2}
          sx={{ padding: props.isNormalSize ? '0px 16px 16px 5px' : '20px', overflow: 'auto' }}
        >
          {cardData.map((card, i) => (
            <Grid item xs={12} sm={6} key={props.pageContent.reviewCards[i].cardTitle}>
              <RescheduleReviewCard
                id={`review-card-${i}`}
                title={props.pageContent.reviewCards[i].cardTitle}
                editLink={props.pageContent.reviewCards[i].editButtonText}
                editLinkHandler={card.editLinkHandler}
                subtitles={card.subtitles}
                icon={card.icon}
              />
            </Grid>
          ))}
        </Grid>
        <TAGModal
          {...props}
          className="WarningModal"
          open={openWarningDialog}
          bodyText={dialogInfo?.bodyText || ''}
          darkScrim={false}
          handleClose={handleWarningDialogClose}
          headlineText={dialogInfo?.headlineText || ''}
          buttonGroup={
            <DialogActions>
              <TAGTextButton onClick={handleWarningDialogClose}>{dialogInfo?.primaryButtonText}</TAGTextButton>
            </DialogActions>
          }
          theme={dialogInfo?.theme || TAGModalTheme.WithRedErrorIcon}
        />
      </GraphQLTemplate>
    </>
  )
}

export default RescheduleReviewPage

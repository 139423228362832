import RJSFBooleanField from '../RJSFInputFields/RJSFBooleanField'
import RJSFCheckboxField from '../RJSFInputFields/RJSFCheckboxfield'
import RJSFCheckboxGroupField from '../RJSFInputFields/RJSFCheckboxGroupField'
import RJSFConditionalField from '../RJSFInputFields/RJSFConditionalField'
import RJSFDateField from '../RJSFInputFields/RJSFDateField'
import RJSFLocationSelectField from '../RJSFInputFields/RJSFLocationSelectField'
import RJSFPhoneField from '../RJSFInputFields/RJSFPhoneField'
import RJSFSelectField from '../RJSFInputFields/RJSFSelectField'
import RJSFTextField from '../RJSFInputFields/RJSFTextField'
import RJSFTimeButtons from '../RJSFInputFields/RJSFTimeButtons'
import RJSFTimePicker from '../RJSFInputFields/RJSFTimePicker'
import RJSFZipcodeField from '../RJSFInputFields/RJSFZipcodeField'

export const fields = {
  StringField: RJSFTextField,
  OneOfField: RJSFBooleanField,
  RJSFLocationSelectField: RJSFLocationSelectField,
  TimePicker: RJSFTimePicker,
  RJSFSelectField: RJSFSelectField,
  ConditionalField: RJSFConditionalField,
  PhoneField: RJSFPhoneField,
  DateField: RJSFDateField,
  ZipcodeField: RJSFZipcodeField,
  ButtonGroupField: RJSFTimeButtons,
  CheckboxField: RJSFCheckboxField,
  CheckboxGroupField: RJSFCheckboxGroupField,
}

export const widgets = {
  CheckboxGroupField: RJSFCheckboxGroupField,
}

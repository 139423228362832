import { UserActions, UserActionTypes } from './user.actions'

export const defaultUserState = {
  user: {},
  rescheduleForm: {},
}
export const userReducer: React.Reducer<any, UserActions> = (state: any, action: UserActions) => {
  switch (action.type) {
    case UserActionTypes.ADD_USER:
      return {
        ...state,
        user: action.user,
      }
    case UserActionTypes.ADD_RESCHEDULE_FORM:
      return {
        ...state,
        rescheduleForm: action.rescheduleForm,
      }
    case UserActionTypes.ADD_VISIT_INFO:
      return {
        ...state,
        visitInfo: action.visitInfo,
      }
    default:
      return defaultUserState
  }
}

import React from 'react'
import { TAGTextFieldInput, Grid, useTheme } from '@aspendental/design-system'
import InputMask from 'react-input-mask'

const RJSFDateField = (props: any) => {
  const theme = useTheme()

  return (
    <Grid item>
      {!props.uiSchema['ui:hidden'] && (
        <InputMask
          mask="99/99/9999"
          placeholder="MM/DD/YYYY"
          maskPlaceholder="MM/DD/YYYY"
          value={props.formData || undefined}
          onChange={(event: { target: { value: any } }) => {
            props.onChange(event.target.value)
          }}
        >
          <TAGTextFieldInput
            fullWidth
            type="tel"
            required={props.required}
            placeholder={props.schema.placeholder}
            color="secondary"
            fieldPlaceHolder={props.schema.placeholder}
            fieldError={props.rawErrors?.length > 0}
            inputLabel={props.schema.label}
            fieldHelperText={props.rawErrors && props.rawErrors[0]}
            value={props.formData || undefined}
            fieldDisabled={props.disabled}
            sx={{
              '& .MuiFormLabel-root': {
                background: '#ffffff',
                fontFamily: theme.typography.bodyMediumBook,
                fontWeight: '300',
              },
              'input::-webkit-calendar-picker-indicator': {
                display: 'none',
                '-webkit-appearance': 'none',
              },
            }}
          />
        </InputMask>
      )}
    </Grid>
  )
}

export default RJSFDateField

import React from 'react'
import { TAGNotification, TAGTypography } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import UserContext from '../../contexts/userContext/user-context'
import endpoints from '../../services/endpoints'
import { calculateRemainingTime } from '../../utils/AuthHelper'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import SubmitButton from '../SubmitButton/SubmitButton'
export interface ILinkSentPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}

const LinkSentPage = (props: ILinkSentPageProps) => {
  const userCtx = React.useContext(UserContext)
  const [resend, setResend] = React.useState<string | null>(null)
  const navigate = useNavigate()
  const linkExpired = () => {
    navigate('/patient-services/expiration')
  }

  const submitHandler = async () => {
    const payload = {
      Email: userCtx.user.email,
    }
    if (!userCtx.user.email) {
      navigate('/patient-services/login')
    }
    const response = await endpoints.sendAuthenticationEmailLink(payload)
    if (response.error) {
      setResend(
        JSON.stringify(response?.error_description) ??
          'Please enter the email address that you entered while scheduling your appointment',
      )
    } else {
      const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000).toISOString()
      const remainingTime = calculateRemainingTime(expirationTime)
      setTimeout(linkExpired, remainingTime)
      setResend('Email has been resent')
    }
  }

  return (
    <FormsMenuTemplate isNormalSize={props.isNormalSize}>
      <FormCredentialTitles isNormalSize={props.isNormalSize} title={props.pageContent.title}>
        <TAGTypography variant={'bodyLargeBook'} color={'text.primary'} gutterBottom>
          {props.pageContent.description}
        </TAGTypography>
      </FormCredentialTitles>
      {!!resend && (
        <TAGNotification elevated={false} theme={'Primary'}>
          {resend}
        </TAGNotification>
      )}
      <SubmitButton
        isNormalSize={props.isNormalSize}
        submitHandler={submitHandler}
        buttonText={props.pageContent.buttonText}
      />
    </FormsMenuTemplate>
  )
}

export default LinkSentPage

import React from 'react'
import TAGLocationPicker from '@aspendental/design-system/lib/components/elements/TAGLocationPicker/TAGLocationPicker'
import TAGNavContainer from '@aspendental/design-system/lib/components/elements/TAGNavigation/TAGNavContainer'
import {
  TAGTypography,
  TAGNavigation,
  TAGTextButton,
  Grid,
  ListItem,
  ListItemText,
  InputAdornment,
  MenuItem,
  Button,
  useMediaQuery,
  TextField,
  FormControl,
  Box,
  useTheme,
} from '@aspendental/design-system'
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined'
import { renderFromHelper } from './renderError'
import { getCoordinates } from '../../../services/api'
import { useLazyQuery } from '@apollo/client'
import { NEABY_LAT_LONG_SEARCH } from '../../../constants/graphqlquery'
import { newformatLocationList } from '../../../utils/Helper'
import LocationCard from '../../ReschedulePage/DateLocationTemplate/NearbyLocationCard'
import UserContext from '../../../contexts/userContext/user-context'

const RJSFLocationSelectField = (props: any) => {
  const [value, setValue] = React.useState(props.formData?.location || undefined)
  const [zipcode, setZipcode] = React.useState<string>('')
  const [open, setOpen] = React.useState(false)
  const [filteredList, setFilteredList] = React.useState<Array<any>>(props.schema.items)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const isNormalSize = useMediaQuery('(min-width:600px)')
  const userCtx = React.useContext(UserContext)
  const theme = useTheme()
  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value)
    const newfilteredList =
      props.schema.items?.length > 0 && props.schema.items?.filter((data: any) => data.addressLine2.includes(zipcode))
    setFilteredList(newfilteredList)
  }

  const handleSelection = (newValue: string) => {
    setValue(newValue)
    const locationObj = filteredList?.length > 0 && filteredList.find((item: any) => item.location === newValue)
    if (locationObj) {
      props.onChange(locationObj)
      userCtx.addRescheduleForm({
        ...(locationObj ? { location: locationObj } : {}),
      })
    } else {
      props.onChange(newValue)
    }
    if (open) {
      setOpen(false)
    }
    if (openDrawer) {
      setOpenDrawer(false)
    }
  }
  const [filterLocation] = useLazyQuery(NEABY_LAT_LONG_SEARCH)
  const onSearchHandler = async () => {
    try {
      const coordinates = await getCoordinates(zipcode)
      if (coordinates) {
        const locationList = await filterLocation({
          variables: {
            longitude: coordinates.longitude,
            latitude: coordinates.latitude,
          },
        })
        if (locationList) {
          const avaLocationList = newformatLocationList(locationList?.data?.nearbyFacilities?.items)
          setFilteredList(avaLocationList)
        }
      }
    } catch (error: any) {
      throw new Error(error.message || 'Something went wrong!')
    }
  }

  const TriggerButton = () => (
    <Button
      className="findMoreOfficesButton"
      onClick={() => {
        setOpen(false)
        setOpenDrawer(true)
        setFilteredList([])
      }}
      color="secondary"
      sx={{ paddingBottom: { xs: '24px', sm: '6px' }, color: 'secondary.main' }}
    >
      Find Another Office
    </Button>
  )
  return (
    <FormControl
      className="locationSelectFormControl"
      fullWidth
      color="secondary"
      sx={{
        '& .MuiFormLabel-root': {
          background: '#ffffff',
          fontFamily: theme.typography.bodyMediumBook,
          fontWeight: '300',
        },
      }}
    >
      <TAGLocationPicker
        className="locationSelectField"
        required={props.required}
        value={value}
        isNormalSize={isNormalSize}
        inputProps={{
          name: props.formData?.location,
          id: 'color-native-simple',
          color: 'black',
        }}
        color="secondary"
        label={props.schema.title}
        open={open}
        onOpen={() => {
          setOpen(true)
          setFilteredList(props.schema.items)
        }}
        onClose={() => setOpen(false)}
        error={props.rawErrors?.length > 0}
        renderValue={(value: any) => value}
        onChange={event => {
          handleSelection((event.target as HTMLInputElement).value as string)
        }}
        startAdornment={
          <InputAdornment position="start">
            <AddLocationOutlinedIcon />
          </InputAdornment>
        }
      >
        {props.schema.items?.length > 0 &&
          props.schema.items.slice(0, 5).map((data: any) => (
            <MenuItem key={data.location} value={data.location} onClick={() => handleSelection(data.location)}>
              <ListItem>
                <ListItemText
                  primary={
                    <Grid container justifyContent={'space-between'}>
                      <TAGTypography variant={'bodySmallBook'}>{data.location}</TAGTypography>
                      <TAGTypography variant={'bodySmallBook'}>{data.distance}</TAGTypography>
                    </Grid>
                  }
                  secondary={
                    <Grid container>
                      <Grid item xs={12}>
                        <TAGTypography variant={'bodySmallBook'}>{data.addressLine1}</TAGTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <TAGTypography variant={'bodySmallBook'}>{data.aligners}</TAGTypography>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            </MenuItem>
          ))}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <TriggerButton />
        </Box>
      </TAGLocationPicker>
      <TAGNavigation
        closeButton
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
        }}
        anchor={'right'}
        width={isNormalSize ? '50%' : '100%'}
      >
        <TAGNavContainer sx={{ padding: isNormalSize ? '40px' : '10px' }}>
          <TAGTypography variant="h3" gutterBottom color="primary">
            Find an office
          </TAGTypography>
          <TAGTypography variant="bodyMediumBook" gutterBottom color="primary">
            Enter your ZIP code to find the nearest office
          </TAGTypography>
          <Grid container>
            <TextField
              sx={{
                width: 300,
                mt: '15px',
                'input::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                },
              }}
              type={'number'}
              id="zip-code"
              label="ZIP code"
              variant="outlined"
              value={zipcode}
              color="secondary"
              onChange={handleZipCodeChange}
              onKeyDown={event => {
                if (zipcode.length === 5 && event.key === 'Enter') {
                  onSearchHandler()
                }
              }}
            />
            {zipcode.length === 5 && (
              <TAGTextButton buttonTheme="TEXTBLUE" onClick={onSearchHandler} className="searchZipcodeButton">
                <TAGTypography variant="buttonLarge">Search</TAGTypography>
              </TAGTextButton>
            )}
          </Grid>
          {props.schema.items?.length > 0 && filteredList && (
            <TAGTextButton
              className="myCurrentLocationButton"
              buttonTheme="TEXTBLUE"
              onClick={() => {
                //return my current location
                const curLocation = props.schema.items[0].addressLine2
                const myCurrentZip = curLocation.substring(curLocation.length - 5)
                setZipcode(myCurrentZip)
              }}
            >
              <TAGTypography variant="buttonLarge">Use my current location</TAGTypography>
            </TAGTextButton>
          )}

          {filteredList?.length > 0 &&
            filteredList.map((data: any) => (
              <MenuItem
                key={data.location}
                value={data.location}
                onClick={() => handleSelection(data.location)}
                sx={{ minHeight: 'auto', my: '20px' }}
              >
                <LocationCard
                  location={{
                    facilityCode: data.facilityCode,
                    location: data.location,
                    distance: data.distance,
                    addressLine1: data.addressLine1,
                    addressLine2: data.addressLine2,
                    phoneNumber: data.phoneNumber,
                  }}
                  matchPhoneSize={false}
                />
              </MenuItem>
            ))}
        </TAGNavContainer>
      </TAGNavigation>
      {renderFromHelper(props.rawErrors)}
    </FormControl>
  )
}

export default RJSFLocationSelectField

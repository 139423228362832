import React from 'react'
import { useQuery } from '@apollo/client'
import { Box, TAGTypography, dataLayerConfig } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import { GET_APPOINTMENT, GET_APPOINTMENT_BY_APPOINTMENT_ID } from '../../constants/graphqlquery'
import { IAvailableLocation, SessionType } from '../../constants/type'
import UserContext from '../../contexts/userContext/user-context'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import SharedForm from '../SharedForms/SharedForm'
import RescheduleFieldTemplate from './RescheduleFieldTemplate'
import { timeWithoutTimeZone } from '../../utils/Helper'
import { format, isAfter } from 'date-fns'
export interface IRescheduleFormProps {
  isNormalSize: boolean
  pageContent: any
  modalContent?: any
  setForm: ((form: any) => void) | undefined
  sessionItem?: SessionType
  errorNavigate?: string
  visitId?: string
}

const RescheduleForm = (props: IRescheduleFormProps) => {
  const isOneClickLogin = globalThis?.sessionStorage.getItem('isOneClickLogin') === 'true'
  const { loading, error, data } = isOneClickLogin
    ? useQuery(GET_APPOINTMENT_BY_APPOINTMENT_ID, { variables: { visitId: props.visitId } })
    : useQuery(GET_APPOINTMENT)
  const oneClickAppointment = data?.me?.scheduledVisit
  const scheduledAppointments = data?.me?.scheduledVisits
  const account = data?.me?.account
  const findRightItem =
    !isOneClickLogin &&
    scheduledAppointments?.items?.length &&
    scheduledAppointments?.items.findIndex((ele: any) => isAfter(new Date(ele.scheduledDateTime), new Date()))
  const initGraph = isOneClickLogin ? oneClickAppointment : scheduledAppointments?.items[findRightItem]
  const userCtx = React.useContext(UserContext)
  const navigate = useNavigate()
  const userName = isOneClickLogin ? account?.firstName : userCtx.user?.firstName
  const params = window.location.search
  const cannotBeRescheduled = initGraph && (!initGraph.canBeRescheduled || !initGraph.facility.code)
  React.useEffect(() => {
    // Virtual page views "needed" for SPA-related pages. includes entire rescheduling flow
    dataLayerConfig({
      event: 'virtualPageview',
      virtualPageURL: 'https://www.aspendental.com/patient-services/reschedule',
      virtualPageTitle: 'Patient Services - Reschedule'
    });

    if (cannotBeRescheduled || !userName) {
      isOneClickLogin
        ? navigate(`/patient-services/reschedule-appointment/${params}`)
        : navigate('/patient-services/appointment')
    }
  }, [])
  return (
    <Box>
      <GraphQLTemplate
        loading={loading}
        error={error}
        data={data}
        sessionItem={props.sessionItem}
        errorNavigate={props.errorNavigate}
      >
        {initGraph && (
          <Box sx={{ padding: props.isNormalSize ? '20px' : '16px 20px' }} justifyContent={'space-between'}>
            <TAGTypography fontStyle="largeOverline" gutterBottom>
              CURRENT APPOINTMENT:
            </TAGTypography>
            <br />
            <br />
            <TAGTypography fontStyle="bodyMediumSemiBold" gutterBottom>
              {`${format(timeWithoutTimeZone(initGraph?.scheduledDateTime), 'EEE. MMMM d, p')}`}
              <br />
              {`${initGraph?.facility.name} | ${initGraph?.facility?.address.address1} ${initGraph?.facility?.address.address2} ${initGraph?.facility?.address.city}, ${initGraph?.facility?.address.stateCode} ${initGraph?.facility?.address.zipCode}`}
            </TAGTypography>
            <br />
            <br />
            <TAGTypography fontStyle="largeOverline" gutterBottom>
              RESCHEDULE APPOINTMENT:
            </TAGTypography>
          </Box>
        )}
        <SharedForm
          formContext={{
            facilityCode: initGraph?.facility.code,
            visitId: initGraph?.visitId,
          }}
          showErrorList={false}
          setForm={props.setForm}
          onSubmit={async (event: any) => {
            const appInfo = event.formData.appointmentTimecode && event.formData.appointmentTimecode.split('$')
            let rightLocation
            if (event.formData.location.facilityCode !== appInfo[0]) {
              rightLocation =
                event.schema.properties.location.items &&
                event.schema.properties.location.items.find(
                  (loc: IAvailableLocation) => loc.facilityCode === appInfo[0],
                )
            }
            if (isOneClickLogin) {
              userCtx.addUserData({
                firstName: account?.firstName,
                lastName: account?.lastName,
                dateOfBirth: account?.birthDate,
              })
            }
            userCtx.addVisitInfo({
              code: appInfo[0],
              visitId: initGraph?.visitId,
              phoneNumber: rightLocation ? rightLocation.phoneNumber : event.formData.location.phoneNumber,
            })
            userCtx.addRescheduleForm({
              ...event.formData,
              ...(rightLocation ? { location: rightLocation } : {}),
            })
            dataLayerConfig({
              event: 'patient_services_reschedule',
              step_name: 'date_location_time'
            })
            isOneClickLogin
              ? navigate(`/patient-services/reschedule-appointment/review/${params}`)
              : navigate('/patient-services/reschedule/review')
          }}
          formData={
            Object.keys(userCtx.rescheduleForm).length !== 0
              ? userCtx.rescheduleForm
              : {
                  calendar: {
                    timeOfDay: 'ALL',
                    weekDays: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
                    timeRanges: [null, null],
                  },
                  location: {
                    location: initGraph?.facility.name,
                    addressLine1: `${initGraph?.facility.address?.address1}`,
                    addressLine2: `${initGraph?.facility.address?.city}, ${initGraph?.facility.address?.stateCode}   ${initGraph?.facility.address?.zipCode}`,
                    distance: '0 mi',
                    phoneNumber: `${initGraph?.facility.phoneNumber}`,
                    facilityCode: initGraph?.facility.code,
                    aligners: 'Clear aligners available',
                  },
                  appointmentTimecode: null,
                }
          }
          ObjectFieldTemplate={rest => (
            <RescheduleFieldTemplate isNormalSize={props.isNormalSize} modalContent={props.modalContent} {...rest} />
          )}
          schemaData={props.pageContent}
          isNormalSize={props.isNormalSize}
        >
          <></>
        </SharedForm>
      </GraphQLTemplate>
    </Box>
  )
}

export default RescheduleForm

import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Chatbot } from '@aspendental/design-system'
import { version } from './package.json'
import './src/styles/global.css'

if (process.env.GATSBY_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.GATSBY_BUGSNAG_API_KEY,
    appVersion: version,
    plugins: [new BugsnagPluginReact()],
    endpoints: {
      notify: 'https://bugsnag-notify.aspendental.com',
      sessions: 'https://bugsnag-sessions.aspendental.com',
    },
  })
}

/**
 * This function is used to wrap the root element. This is useful to set up any Provider components that will wrap your application.
 * @param {ReactNode} element The “Page” React Element built by Gatsby
 */
export const wrapRootElement = ({ element }) => {
  // TODO: initialize contexts and client-side DB here
  //  https://aspendent.atlassian.net/wiki/spaces/TECH/pages/2337931318/Application+State+-+Standards+-+Web#Application-State
  return <>{element}</>
}

import React from 'react'
import { Container, dataLayerConfig } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import UserContext from '../../contexts/userContext/user-context'
import endpoints from '../../services/endpoints'
import { calculateRemainingTime } from '../../utils/AuthHelper'
import { renderFromHelper } from '../SharedForms/RJSFInputFields/renderError'
import SharedForm from '../SharedForms/SharedForm'
import SubmitButton from '../SubmitButton/SubmitButton'
export interface ILoginFormProps {
  isNormalSize: boolean
  pageContent: any
}

const LoginForm = (props: ILoginFormProps) => {
  const userCtx = React.useContext(UserContext)
  const navigate = useNavigate()
  const [error, setError] = React.useState<string>('')
  const [disableButton, setDisableButton] = React.useState<boolean>(false)

  const linkExpired = () => {
    navigate('/patient-services/expiration')
  }
  const onEmailLogin = async (emailPayload: any) => {
    setDisableButton(true)
    setError('')
    const payload = {
      Email: emailPayload?.emailAddress,
    }
    userCtx.addUserData({ email: emailPayload?.emailAddress })
    const response = await endpoints.sendAuthenticationEmailLink(payload)
    const expirationTime = new Date(new Date().getTime() + 60 * 60 * 1000).toISOString()
    const remainingTime = calculateRemainingTime(expirationTime)
    setTimeout(() => globalThis?.sessionStorage.removeItem('isLogin'), remainingTime)
    setDisableButton(!!response)
    if (response.error) {
      if (response.error_description === 'InvalidEmail') {
        setError('Please enter an email address that is associated with an account.')
      } else {
        setError(JSON.stringify(response?.error) ?? 'Invalid')
      }
    } else {
      const expirationTime = new Date(new Date().getTime() + 10 * 60 * 1000).toISOString()
      const remainingTime = calculateRemainingTime(expirationTime)
      dataLayerConfig({
        event: 'patient_services_login',
        step_name: 'authentication_1', 
        aspen_action: 'link_sent'
      });
      setTimeout(linkExpired, remainingTime)
      navigate('/patient-services/link-sent')
    }
  }

  return (
    <Container maxWidth="xs">
      <SharedForm
        schemaData={props.pageContent.contents[1]}
        isNormalSize={props.isNormalSize}
        onSubmit={async (event: any) => {
          onEmailLogin(event.formData)
        }}
      >
        {renderFromHelper(error)}
        <SubmitButton
          isNormalSize={props.isNormalSize}
          buttonText={props.pageContent.contents[0].formButtonPrimary}
          disableButton={disableButton}
        />
      </SharedForm>
    </Container>
  )
}

export default LoginForm

import { TAGTypography } from '@aspendental/design-system'
import React from 'react'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import AuthForm from './AuthForm'
export interface IAuthPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}
const AuthPage = (props: IAuthPageProps) => {
  return (
    <FormsMenuTemplate isNormalSize={props.isNormalSize}>
      <FormCredentialTitles isNormalSize={props.isNormalSize} title={props.pageContent.title}>
        <TAGTypography variant={'bodyLargeBook'} color={'text.primary'} gutterBottom>
          {props.pageContent.description}
        </TAGTypography>
      </FormCredentialTitles>
      <AuthForm isNormalSize={props.isNormalSize} pageContent={props.pageContent} />
    </FormsMenuTemplate>
  )
}

export default AuthPage

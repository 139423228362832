import React from 'react'
import FormContext from './form-context'
import { FormActionTypes } from './form.actions'
import { defaultFormState, formReducer } from './formReducer'

interface IFormProvider {
  children: React.ReactNode
}

const FormProvider = (props: IFormProvider) => {
  const [formState, dispatchFormAction] = React.useReducer(formReducer, defaultFormState)

  const addingFormData = (payload: { form: object; formName: string }) => {
    dispatchFormAction({ type: FormActionTypes.ADD_FORM, payload: payload })
  }

  const formContext = {
    forms: formState.forms,
    addFormData: addingFormData,
  }

  return <FormContext.Provider value={formContext}>{props.children}</FormContext.Provider>
}

export default FormProvider

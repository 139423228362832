import React from 'react'
import { TAGNotification, TAGTypography, Grid } from '@aspendental/design-system'
import AvailableNearByCard from './AvailableNearByCard'
import { IAvailableLocation } from '../../../constants/type'
interface INearBySectionProps {
  isNormalSize: boolean
  nearByLocations: Array<IAvailableLocation> | null
  seletedBtn: string | undefined
  updateSeletedButton: (btn: string) => void
  setNewNearby: (code: string) => void
  notificationText: string
  numberOfDays: number
}
const NearBySection = (props: INearBySectionProps) => {
  return (
    <>
      {props.nearByLocations && props.nearByLocations.length > 0 && (
        <TAGTypography
          color={'text.primary'}
          variant={'h3'}
          mb={2}
          sx={{ textAlign: { xs: 'center', sm: 'start' } }}
          className={'nearbyTitle'}
        >
          Available appointments nearby
        </TAGTypography>
      )}
      {props.nearByLocations ? (
        props.nearByLocations
          .slice(0, 4)
          .map(location => (
            <AvailableNearByCard
              key={location.facilityCode}
              location={location}
              isNormalSize={props.isNormalSize}
              seletedBtn={props.seletedBtn}
              updateSeletedButton={props.updateSeletedButton}
              setNewNearby={props.setNewNearby}
              notificationText={props.notificationText}
              numberOfDays={props.numberOfDays}
            />
          ))
      ) : (
        <Grid sx={{ padding: '0px 20px' }}>
          <TAGNotification theme={'Warning'} elevated={false} button buttonName="Reset filter">
            Sorry, there are no online appointments nearby location available at this time. Please call (XXX) XXX-XXXX
            or select another office.
          </TAGNotification>
        </Grid>
      )}
    </>
  )
}

export default NearBySection

import { Box, TAGContainedButton } from '@aspendental/design-system'
import React from 'react'
interface IDTSubmitButtonProp {
  buttonText: string
  buttonHandler?: (event: any) => void
  disabledButton?: boolean
}

const FloatingButton = (props: IDTSubmitButtonProp) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        pt: 2,
        pb: 2,
        justifyContent: 'center',
        bgcolor: 'backgrounds.default',
        color: 'text.light',
        position: 'sticky',
        bottom: '0px',
        zIndex: 1200,
        boxShadow: '0 -1px 4px rgb(0 0 0 / 20%)',
      }}
    >
      {props.buttonText && (
        <TAGContainedButton
          disabled={props.disabledButton ? props.disabledButton : false}
          buttonSize="large"
          buttonTheme="CONTAINEDBLUE"
          type="submit"
          id={'continueButton'}
          onClick={props.buttonHandler && props.buttonHandler}
        >
          {props.buttonText}
        </TAGContainedButton>
      )}
    </Box>
  )
}

export default FloatingButton

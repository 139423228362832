import { Box, Grid, TAGTypography } from '@aspendental/design-system'
import { TAGVariantType } from '@aspendental/design-system/lib/constants/type'
import React from 'react'
export interface IFormCredentialTitlesProps {
  isNormalSize: boolean
  title: string
  titleFont?: TAGVariantType
  children?: React.ReactNode | React.ReactNode[] | undefined
}
const FormCredentialTitles = (props: IFormCredentialTitlesProps) => {
  return (
    <Box marginY={2}>
      <Grid item>
        <TAGTypography variant={props.titleFont ?? 'h1'} color="text.primary" gutterBottom>
          {props.title}
        </TAGTypography>
      </Grid>
      {props.children}
    </Box>
  )
}

export default FormCredentialTitles

import * as React from 'react'
import { Grid, CircularProgress } from '@aspendental/design-system'

export default function Spinner() {
  return (
    <Grid item sx={{ width: '100%', textAlign: 'center' }}>
      <CircularProgress color="secondary" />
    </Grid>
  )
}

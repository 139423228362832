import React from 'react'
import { Container, dataLayerConfig } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import SubmitButton from '../SubmitButton/SubmitButton'
import SharedForm from '../SharedForms/SharedForm'
import endpoints from '../../services/endpoints'
import { renderFromHelper } from '../SharedForms/RJSFInputFields/renderError'
import UserContext from '../../contexts/userContext/user-context'
import { calculateRemainingTime } from '../../utils/AuthHelper'
import { format } from 'date-fns'
export interface IAuthFormProps {
  isNormalSize: boolean
  pageContent: any
}
const AuthForm = (props: IAuthFormProps) => {
  const navigate = useNavigate()
  const [error, setError] = React.useState<string>('')
  const userCtx = React.useContext(UserContext)

  const onLogout = () => {
    globalThis?.sessionStorage.removeItem('isLogin')
  }

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const softToken = urlParams.get('token') ?? ''

  const authLogin = async (loginPayload: any) => {
    const dob = format(new Date(loginPayload?.dateOfBirth), 'yyyy-MM-dd')
    setError('')

    const noAccountpayload = {
      firstName: loginPayload?.firstName,
      lastName: loginPayload?.lastName,
      dateOfBirth: dob,
    }

    const response = await endpoints.authenticateViaEmailLink(noAccountpayload, 'Bearer ' + softToken)
    if (response.error) {
      if (response.error_description === 'InvalidToken') {
        navigate('/patient-services/expiration')
        return
      }
      if (response.error_description === 'InvalidInputFields') {
        setError('Invalid credentials')
        return
      }
      setError(JSON.stringify(response?.error) ?? 'Unable to login')
    } else {
      userCtx.addUserData({
        firstName: loginPayload?.firstName,
        lastName: loginPayload?.lastName,
        dateOfBirth: dob,
      })
      const expirationTime = new Date(new Date().getTime() + 60 * 60 * 1000).toISOString()
      const remainingTime = calculateRemainingTime(expirationTime)
      dataLayerConfig({
        event: 'patient_services_login',
        step_name: 'authentication_2', 
        link_url: 'https://www.aspendental.com/patient-services/appointment'
      })
      setTimeout(onLogout, remainingTime)
      globalThis?.sessionStorage.setItem('isLogin', 'true')
      navigate(props.pageContent.contents[0].formButtonPrimaryURL)
      return
    }
  }

  return (
    <Container maxWidth="xs">
      <SharedForm
        schemaData={props.pageContent.contents[0]}
        isNormalSize={props.isNormalSize}
        onSubmit={async (event: any) => {
          authLogin(event.formData)
        }}
      >
        {renderFromHelper(error)}
        <SubmitButton isNormalSize={props.isNormalSize} buttonText={props.pageContent.contents[0].formButtonPrimary} />
      </SharedForm>
    </Container>
  )
}

export default AuthForm

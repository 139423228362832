import React from 'react'
import { RichText } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import SubmitButton from '../SubmitButton/SubmitButton'

export interface IExpirationPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}
const ExpirationPage = (props: IExpirationPageProps) => {
  const navigate = useNavigate()
  const richText = props.pageContent?.contents[0]?.content?.raw

  const submitHandler = async () => {
    navigate('/patient-services/login')
  }

  return (
    <FormsMenuTemplate isNormalSize={props.isNormalSize}>
      <FormCredentialTitles isNormalSize={props.isNormalSize} title={props.pageContent.title}>
        {richText && (
          <RichText
            centerAlign
            branding={'aspendental'}
            content={{
              raw: richText,
              references: [],
            }}
          ></RichText>
        )}
      </FormCredentialTitles>
      <SubmitButton
        isNormalSize={props.isNormalSize}
        submitHandler={submitHandler}
        buttonText={props.pageContent.buttonText}
      />
    </FormsMenuTemplate>
  )
}

export default ExpirationPage

/* eslint-disable @typescript-eslint/no-empty-function */
import { Grid, TAGButton, TAGTypography } from '@aspendental/design-system'
import TAGPickerFilterTabContent, {
  AMPMType,
  DayOfWeekType,
} from '@aspendental/design-system/lib/components/elements/TAGDateTimePicker/TAGPickerFilterTabContent'
import React from 'react'
import { newformatLocationList, newformatNearByList, restructSlots } from '../../utils/Helper'
import DateSection from './DateAndTime/DateSection'
import FilterDialog from './DateAndTime/FilterDialog'
import Show3DaySwitch from './DateLocationTemplate/Show3DaySwitch'
import NearBySection from './NearBySection/NearBySection'
import { format, addDays } from 'date-fns'
import { useQuery } from '@apollo/client'
import { GET_NEARBY } from '../../constants/graphqlquery'
import UserContext from '../../contexts/userContext/user-context'
import GraphQLTemplate from '../GraphQLTemplate/GraphQLTemplate'
import { IAvailableLocation } from '../../constants/type'

const RescheduleFieldTemplate = (props: any) => {
  const INIT_NUM_DAYS = 32
  const [show3Days, setShow3Days] = React.useState<boolean>(false)
  const [selectedBtn, setSelectedBtn] = React.useState('')
  const userCtx = React.useContext(UserContext)
  const [payload, setPayload] = React.useState({})
  const [numberOfDays, setNumberOfDays] = React.useState(4)
  const [ampm, setAmpm] = React.useState('ALL' as AMPMType)
  const [dayOfWeek, setDayOfWeek] = React.useState<Array<DayOfWeekType>>(() => [])
  const ampmHandler = () => {
    setAmpm((event?.target as HTMLInputElement).value as AMPMType)
  }
  const handleDayOfWeek = (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    const newValue = (event?.currentTarget as HTMLInputElement).value as DayOfWeekType
    if (dayOfWeek && dayOfWeek.includes(newValue)) {
      setDayOfWeek(dayOfWeek.filter((item: string) => item !== newValue))
    } else {
      setDayOfWeek(dayOfWeek.concat(newValue))
    }
  }
  const [filterPayload, setFilterPayload] = React.useState({
    daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
    partOfDay: null,
  })
  const onFilterDate = () => {
    if (props.formData?.location.facilityCode) {
      setFilterPayload({
        daysOfWeek: dayOfWeek.map((day: string) => day.toUpperCase()),
        partOfDay: timeOfDay === 'ALL' ? null : timeOfDay,
      })
    }
    setDayOfWeek([])
    setAmpm('ALL')
  }
  React.useEffect(() => {
    if (props.formData?.appointmentTimecode) {
      setSelectedBtn(props.formData?.appointmentTimecode)
    }
  }, [])
  const endDate =
    props.formData.calendar.timeRanges && props.formData.calendar.timeRanges[0] !== null
      ? props.formData.calendar.timeRanges[0]
      : addDays(new Date(), numberOfDays)
  const timeOfDay = props.isNormalSize ? ampm : props.schema.properties.calendar.timeOfDay
  const queryPayload = {
    variables: {
      facilityCode: props.formData.location?.facilityCode ?? props.formContext.facilityCode,
      withinRadius: 200,
      takeFacilities: 5,
      dateFrom: format(
        props.formData?.calendar.timeRanges && props.formData?.calendar.timeRanges[0] !== null
          ? props.formData.calendar.timeRanges[0]
          : new Date(),
        'yyyy-MM-dd',
      ),
      dateTo: format(
        props.formData?.calendar.timeRanges && props.formData?.calendar.timeRanges[1] !== null
          ? props.formData.calendar.timeRanges[1]
          : endDate,
        'yyyy-MM-dd',
      ),
      scheduledVisitId: props.formContext.visitId,
      ...(props.isNormalSize
        ? filterPayload
        : {
            daysOfWeek: props.schema.properties.calendar?.weekDays.map((day: string) => day.toUpperCase()),
            partOfDay: timeOfDay === 'ALL' ? null : timeOfDay,
          }),
      ...payload,
    },
  }
  const { loading, error, data } = useQuery(GET_NEARBY, queryPayload)

  const timeSlots = restructSlots(data?.facility?.rescheduleSlots?.items)

  const getLocationList = async () => {
    const locationList = await newformatLocationList(data?.facility?.nearbyRescheduleFacilities?.items)
    props.schema.properties.location.items =
      !locationList || locationList.length === 0 ? [props.formData.location] : locationList
  }

  const handleSetNewNearby = (code: string) => {
    setSelectedBtn(props.formData)
    const curLocation =
      props.schema.properties.location.items &&
      props.schema.properties.location.items.find((loc: IAvailableLocation) => loc.facilityCode === code)
    if (curLocation) {
      userCtx.addRescheduleForm({
        ...props.formData,
        ...(curLocation ? { location: curLocation } : {}),
      })
      setPayload({
        facilityCode: code,
      })
    }
  }

  const handleShow3Days = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShow3Days(event.target.checked)
    if (event.target.checked) {
      const threeDayPayload = {
        daysOfWeek: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'],
        partOfDay: null,
        dateFrom: format(addDays(new Date(), 0), 'yyyy-MM-dd'),
        dateTo: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
      }
      setPayload(threeDayPayload)
    } else {
      if (props.formData?.location.facilityCode) {
        setPayload({})
      }
    }
  }
  const loadMoreDays = () => {
    if (numberOfDays > INIT_NUM_DAYS) {
      return
    }
    setNumberOfDays(days => days + 5)
  }
  const updateSeletedButton = (btn: string) => {
    setSelectedBtn(btn)
    props.properties[2].content.props.onChange(btn)
  }

  React.useEffect(() => {
    getLocationList()
  }, [data])
  return (
    <>
      <Grid container spacing={2} sx={{ padding: props.isNormalSize ? '20px' : '16px 20px' }}>
        <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' }, paddingBottom: 2 }}>
          <TAGTypography variant={'h2'} color="primary" className="dateLocationTitle">
            {props.schema.label}
          </TAGTypography>
        </Grid>
        <Grid
          item
          xs={props.properties[0].content.props.uiSchema['ui:phoneSize']}
          sm={props.properties[0].content.props.uiSchema['ui:size'] || 6}
        >
          {props.properties[0].content}
        </Grid>
        <Grid
          item
          xs={props.properties[1].content.props.uiSchema['ui:phoneSize']}
          sm={props.properties[1].content.props.uiSchema['ui:size'] || 6}
        >
          {props.properties[1].content}
        </Grid>
        <Grid item sx={{ marginLeft: 'auto', display: { sm: 'flex', xs: 'none' } }} sm={2} mb={2}>
          <FilterDialog onApply={onFilterDate}>
            <TAGPickerFilterTabContent
              ampmButton={ampm}
              onChangAmPm={ampmHandler}
              weekOfDayButton={dayOfWeek}
              onChangesetWeekOfDay={handleDayOfWeek}
              color={'tertiary.dark'}
            />
          </FilterDialog>
        </Grid>
      </Grid>
      <Grid item sx={{ display: 'flex', alignItems: 'center' }} pb={1}>
        <Show3DaySwitch show3Days={show3Days} handleShow3Days={handleShow3Days} />
      </Grid>
      <Grid item xs={12} pb={1} paddingX={props.isNormalSize ? '' : '5px'}>
        {props.properties[2].content}
      </Grid>
      <GraphQLTemplate loading={loading} error={error} data={data}>
        <DateSection
          availableTime={timeSlots}
          facilityCode={data?.facility?.code}
          isNormalSize={props.isNormalSize}
          updateSeletedButton={updateSeletedButton}
          seletedBtn={selectedBtn}
          notificationText={props.modalContent.headlineText}
          numberOfDays={props.isNormalSize ? numberOfDays : 8}
        />
        <Grid container item sx={{ alignItems: 'center' }} direction={'column'} pb={1}>
          {props.isNormalSize && timeSlots?.length > 0 && numberOfDays <= INIT_NUM_DAYS && !show3Days ? (
            <TAGButton
              id="loadMoreButton"
              size="small"
              sx={{ borderRadius: '40px', marginX: 'auto' }}
              onClick={loadMoreDays}
            >
              <TAGTypography variant={'buttonLarge'}>Load More</TAGTypography>
            </TAGButton>
          ) : (
            !props.isNormalSize &&
            timeSlots?.length > 0 && (
              <>
                <TAGTypography sx={{ margin: 'auto' }}>Need to see more appointment availability?</TAGTypography>
                <TAGButton
                  sx={{ margin: 'auto' }}
                  buttonVariant="Primary High Contrast"
                  onClick={() => window.scrollTo(0, 0)}
                  className="backToCalendarButton"
                >
                  Back To Calendar
                </TAGButton>
              </>
            )
          )}
        </Grid>
        {data?.facility?.nearbyRescheduleFacilities?.items && (
          <Grid
            item
            sx={{
              marginX: props.isNormalSize ? '16px' : '0px',
            }}
          >
            <NearBySection
              isNormalSize={props.isNormalSize}
              nearByLocations={newformatNearByList(data?.facility?.nearbyRescheduleFacilities?.items)}
              seletedBtn={selectedBtn}
              updateSeletedButton={updateSeletedButton}
              setNewNearby={handleSetNewNearby}
              notificationText={''}
              numberOfDays={3}
            />
          </Grid>
        )}
      </GraphQLTemplate>
    </>
  )
}

export default RescheduleFieldTemplate

import React from 'react'
import { TAGTextFieldInput, Grid, useTheme } from '@aspendental/design-system'

const RJSFTextField = (props: any) => {
  const theme = useTheme()
  return (
    <Grid item>
      {!props.uiSchema['ui:hidden'] && (
        <TAGTextFieldInput
          fullWidth
          type={props.name === 'password' ? 'password' : ''}
          required={props.required}
          placeholder={props.schema.placeholder}
          color="secondary"
          fieldPlaceHolder={props.schema.placeholder}
          fieldError={props.rawErrors?.length > 0}
          inputLabel={props.schema.label}
          fieldHelperText={props.rawErrors && props.rawErrors[0]}
          value={props.formData || undefined}
          onChange={event => props.onChange(event.target.value)}
          fieldDisabled={props.disabled}
          sx={{
            'input::-webkit-inner-spin-button': {
              '-webkit-appearance': 'none',
            },
            'input::-webkit-calendar-picker-indicator': {
              display: 'none',
              '-webkit-appearance': 'none',
            },
            '& .MuiFormLabel-root': {
              transform: props.name === 'dateOfBirth' && 'translate(14px, -6px) scale(0.75)',
              padding: props.name === 'dateOfBirth' && '0 2px',
              background: '#ffffff',
              fontFamily: theme.typography.bodyMediumBook,
              fontWeight: '300',
            },
          }}
        />
      )}
    </Grid>
  )
}

export default RJSFTextField

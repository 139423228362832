/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { FormContextType } from './form.actions'
const initialState: FormContextType = {
  forms: { PersonalInfo: Object, MedicalHistory: Object, AuthConsent: Object },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addFormData: (_payload: { form: object; formName: string }) => {},
}
const FormContext = React.createContext(initialState)

export default FormContext

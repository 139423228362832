import React from 'react'
import { TAGTypography } from '@aspendental/design-system'
import FormCredentialTitles from '../FormsMenuPage/FormCredentialTitles'
import FormsMenuTemplate from '../FormsMenuPage/FormsMenuTemplate'
import LoginForm from '../LinkSentPage/LoginForm'

export interface IEmailLinkPageProps {
  isNormalSize: boolean
  path: string
  pageContent: any
}

const EmailLinkPage = (props: IEmailLinkPageProps) => {
  return (
    <FormsMenuTemplate isNormalSize={props.isNormalSize}>
      <FormCredentialTitles isNormalSize={props.isNormalSize} title={props.pageContent.title}>
        <TAGTypography variant={'bodyLargeBook'} color={'text.primary'} gutterBottom>
          {props.pageContent.description}
        </TAGTypography>
      </FormCredentialTitles>
      <LoginForm isNormalSize={props.isNormalSize} pageContent={props.pageContent} />
    </FormsMenuTemplate>
  )
}

export default EmailLinkPage

import { graphql } from 'gatsby'
import {
  useMediaQuery,
  useInitAppReducer,
  useInitFacilityReducer,
  TAGThemeProvider,
  Header,
  Footer,
  getSessionId,
  Analytics,
  Chatbot,
  getEnv,
} from '@aspendental/design-system'
import ConfirmPage from '../components/ConfirmFormPage/ConfirmPage'
import AuthPage from '../components/AuthPage/AuthPage'
import { Router, Redirect } from '@reach/router'
import ExpirationPage from '../components/ExpirationPage/ExpirationPage'
import FormProvider from '../contexts/formContext/FormProvider'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import React, { FC } from 'react'
import { apiClient } from '../services/apiClient'
import UserProvider from '../contexts/userContext/UserProvider'
import PatientServicesPage from '../components/PatientServicesPage/PatientServicesPage'
import AppointmentPage from '../components/AppointmentPage/AppointmentPage'
import RequireAuth from '../components/AuthPage/RequireAuth'
import ReschedulePage from '../components/ReschedulePage/ReschedulePage'
import EmailLinkPage from '../components/AuthPage/EmailLinkPage'
import LinkSentPage from '../components/LinkSentPage/LinkSentPage'
import { getConfig } from '../utils/getConfig'
import OneClickReschedule from '../components/OneClickReschedule/OneClickReschedule'
import OneClickLinkExpiredPage from '../components/OneClickLinkExpiredPage/OneClickLinkExpiredPage'
import OneClickPrePage from '../components/OneClickPrePage/OneClickPrePage'
import OneClickCancelPage from '../components/OneClickCancelPage/OneClickCancelPage'
import CanceledPage from '../components/CanceledPage/CanceledPage'
import OneClickConfirmationPage from '../components/OneClickConfirmationPage/OneClickConfirmationPage'
import OneClickConfirmPage from '../components/OneClickConfirmPage/OneClickConfirmPage'

export const Home: FC = ({ data }: any) => {
  const contentfulData = data.contentfulApp
  const otherAppsPages = data.otherAppsPages
  const config = getConfig()
  const env = getEnv()
  const chatbotUrl =
    env === 'nonprod' ? process.env.GATSBY_CHATBOT_URL_STAGE || '' : process.env.GATSBY_CHATBOT_URL_PROD || ''

  apiClient.setApiName(contentfulData.name)
  const isNormalSize = useMediaQuery('(min-width:600px)')

  useInitAppReducer({
    pages: otherAppsPages.nodes.flatMap((arr: any) => arr.pages),
  })
  useInitFacilityReducer()

  const client = new ApolloClient({
    uri: `${config.ENTERPRISE_API_URL}bbff/websitegraphql/graphql/`,
    credentials: 'include',
    headers: {
      'x-client-application': contentfulData.name,
      'x-session-id': getSessionId(),
    },
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })

  apiClient.setEnterpriseUrl(config.ENTERPRISE_API_URL)
  return (
    <>
      <Analytics vwoAccountId={process.env.GATSBY_VWO_ACCOUNT_ID} />
      <TAGThemeProvider branding={contentfulData.branding}>
        <Header contentfulData={contentfulData} usesRehydration />
        <ApolloProvider client={client}>
          <UserProvider>
            <FormProvider>
              <Router>
                <AuthPage
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[1].path}
                  pageContent={contentfulData.pages[1]}
                />
                <EmailLinkPage
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[10].path}
                  pageContent={contentfulData.pages[10]}
                />
                <LinkSentPage
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[9].path}
                  pageContent={contentfulData.pages[9]}
                />
                <ExpirationPage
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[2].path}
                  pageContent={contentfulData.pages[2]}
                />
                <ConfirmPage
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[7].path}
                  pageContent={contentfulData.pages[7]}
                />
                <PatientServicesPage
                  isNormalSize={isNormalSize}
                  path={'/patient-services'}
                  pageContent={contentfulData.pages[11]}
                />
                <RequireAuth
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[12].path}
                  pageContent={contentfulData}
                  enfant={<AppointmentPage />}
                />
                <RequireAuth
                  isNormalSize={isNormalSize}
                  path={`${contentfulData.pages[8].path}/*`}
                  pageContent={contentfulData}
                  enfant={<ReschedulePage />}
                />
                <OneClickPrePage
                  enfant={<OneClickReschedule />}
                  pageContent={contentfulData}
                  isNormalSize={isNormalSize}
                  path={'/patient-services/reschedule-appointment/*'}
                />
                <OneClickPrePage
                  enfant={<OneClickCancelPage />}
                  pageContent={contentfulData}
                  isNormalSize={isNormalSize}
                  path={'/patient-services/cancel-appointment'}
                />
                <OneClickPrePage
                  enfant={<OneClickConfirmationPage />}
                  isNormalSize={isNormalSize}
                  path={'/patient-services/v2/confirm-appointment'}
                  pageContent={contentfulData.pages[13]}
                />
                <OneClickConfirmPage
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[13].path}
                  pageContent={contentfulData.pages[13]}
                />
                <OneClickLinkExpiredPage
                  isNormalSize={isNormalSize}
                  path={'/patient-services/one-click-expiration'}
                  pageContent={contentfulData.pages[2]}
                />
                <CanceledPage
                  isNormalSize={isNormalSize}
                  path={contentfulData.pages[14].path}
                  pageContent={contentfulData.pages[14]}
                />
                <Redirect from="*" to={contentfulData.pages[10].path} noThrow />
              </Router>
            </FormProvider>
          </UserProvider>
        </ApolloProvider>
        <Footer contentfulData={contentfulData} />
        {env === 'nonprod' && <Chatbot url={chatbotUrl} />}
      </TAGThemeProvider>
    </>
  )
}

export default Home
export const query = graphql`
  {
    otherAppsPages: allContentfulApp(
      filter: {
        name: {
          in: [
            "AspenDental - Marketing"
            "AspenDental - Search"
            "AspenDental - Scheduling"
            "AspenDental - Office Details"
          ]
        }
      }
    ) {
      nodes {
        pages {
          path
        }
      }
    }
    contentfulApp(name: { eq: "AspenDental - Patient Services" }) {
      id
      branding
      name
      logo {
        file {
          url
        }
      }
      header {
        locationBarFindOfficeUrl
        locationBarWithOfficeTitle
        locationBarWithOutOfficeTitle
        useNewLayout
        schedulingButtonText
        schedulingButtonTextMobile
        schedulingButtonUrl
        schedulingButtonUrlMobile
        myAccountLabel
        navigationLinks {
          ... on ContentfulPage {
            id
            name
            __typename
            breadcrumbTitle
            path
          }
        }
      }
      pages {
        description
        title
        path
        name
        breadcrumbTitle
        breadcrumbs {
          breadcrumbTitle
          path
        }
        pageTitle
        buttonText
        buttonUrl
        contents {
          ... on ContentfulSharedForm {
            id
            formButtonPrimary
            formButtonPrimaryURL
            formButtonSecondary
            formButtonSecondaryURL
            title
            titleColor
            label
            titleFont
            descriptionColor
            formFields {
              title
              type
              phoneSize
              size
              field
              createNewLine
              divider
              label
              needOuterLabel
              required
              format
              minLength
            }
            definitions {
              createNewLine
              displayDefinition
              description
              divider
              label
              needLabel
              phoneSize
              size
              title
              formFields {
                createNewLine
                divider
                field
                label
                needOuterLabel
                phoneSize
                size
                title
                required
                type
                enum
                minLength
                items
                format
                dependency {
                  condition
                  dependencyDef
                  showFieldPath
                }
              }
            }
          }
          ... on ContentfulRichText {
            id
            name
            content {
              raw
            }
          }
          ... on ContentfulMultiCard {
            id
            name
            cards {
              title
              buttonUrl
              buttonText
              eventType
              stepName
              name
              image {
                url
              }
            }
          }
          ... on ContentfulStepper {
            name
            headingText
            headingScheduledText
            needAssistanceText
            steps {
              ... on ContentfulSharedForm {
                id
                formButtonPrimary
                formButtonPrimaryURL
                formButtonSecondary
                formButtonSecondaryURL
                title
                titleColor
                label
                titleFont
                descriptionColor
                formFields {
                  title
                  type
                  phoneSize
                  size
                  field
                  createNewLine
                  divider
                  label
                  needOuterLabel
                  required
                  format
                  minLength
                }
                definitions {
                  createNewLine
                  displayDefinition
                  description
                  divider
                  label
                  needLabel
                  phoneSize
                  size
                  title
                  formFields {
                    createNewLine
                    divider
                    field
                    label
                    needOuterLabel
                    phoneSize
                    size
                    title
                    required
                    type
                    enum
                    minLength
                    items
                    format
                    dependency {
                      condition
                      dependencyDef
                      showFieldPath
                    }
                  }
                }
              }
              ... on ContentfulReviewStep {
                id
                reviewStepName
                reviewCards {
                  cardTitle
                  cardName
                  editButtonText
                }
              }
              ... on ContentfulConfirmationStep {
                id
                confirmationPageName
                footerText {
                  footerText
                }
              }
            }
            confirmButtonText
            continueButtonText
          }
          ... on ContentfulHeroV2 {
            __typename
            contentful_id
            id
            heading {
              raw
            }
            body {
              raw
            }
            headingSize
            bodySize
            headingSize
            backgroundColorSmallScreen
            bodySize
            darkOverlay
            eyebrowStyle
            eyebrow
            contentPosition
            contentAlign
            templateWidth
            darkOverlay
            mediaAspectRatioSmallScreen
            focalPointImage {
              image {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                description
                file {
                  details {
                    image {
                      height
                      width
                    }
                    size
                  }
                  url
                }
              }
              name
              focalPoint {
                focalPoint {
                  x
                  y
                }
              }
            }
            contentSmallScreen {
              contentful_id
              bodySmallScreen {
                raw
              }
              id
              headingSmallScreen {
                raw
              }
              focalPointImageSmallScreen {
                __typename
                contentful_id
                image {
                  description
                  file {
                    contentType
                    url
                  }
                  gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
                focalPoint {
                  focalPoint {
                    x
                    y
                  }
                }
              }
            }
          }
          ... on ContentfulModal {
            __typename
            contentful_id
            modalTitle
            bodyText
            headlineText
            primaryButtonText
            secondaryButtonText
          }
        }
      }
      footer {
        logo {
          file {
            url
          }
        }
        navigationGroups {
          id
          navigationLinksForGroup {
            ... on ContentfulPage {
              id
              path
              description
              title
              breadcrumbTitle
            }
          }
          navigationGroupLink {
            id
            title
            description
            path
            breadcrumbTitle
          }
        }
        socialNetworks {
          id
          path
          description
          title
          image {
            file {
              url
            }
          }
        }
        contactNumber
        copyright
        about {
          raw
        }
      }
      logoMobile {
        file {
          url
        }
      }
    }
  }
`

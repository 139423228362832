import React from 'react'
import { TAGTextFieldInput, Grid, useTheme } from '@aspendental/design-system'

const RJSFPhoneField = (props: any) => {
  const disallowedCharactersRe = /[^+()0-9\- ]/
  const theme = useTheme()

  function handleChange(phoneNumber: string) {
    // If it isn't an allowed character, don't process it.
    const hasDisallowedCharacters = phoneNumber.match(disallowedCharactersRe)

    // formatting happens in here
    function formatPhoneValue(phone: string) {
      if (!phone) {
        return ''
      } else if (phone.length <= 3) {
        phone = `(${phone.slice(0)}`
      } else if (phone.length > 3 && phone.length <= 6) phone = `(${phone.slice(0, 3)})` + ' ' + phone.slice(3)
      else if (phone.length > 6) phone = `(${phone.slice(0, 3)})` + ' ' + phone.slice(3, 6) + '-' + phone.slice(6)

      return phone
    }

    if (!hasDisallowedCharacters) {
      const targetValue = phoneNumber.replace(/[^\d]/g, '')
      const transformedValue = formatPhoneValue(targetValue)
      return transformedValue
      /* this is where we have to return call back function
      came from consumer app */
    }
  }

  return (
    <Grid item>
      {!props.uiSchema['ui:hidden'] && (
        <TAGTextFieldInput
          type="text"
          fullWidth
          required={props.required}
          placeholder={props.schema.placeholder}
          color="secondary"
          fieldPlaceHolder={props.schema.placeholder}
          fieldError={props.rawErrors?.length > 0}
          inputLabel={props.schema.label}
          fieldHelperText={props.rawErrors && props.rawErrors[0]}
          value={props.formData || undefined}
          onChange={event => props.onChange(handleChange(event.target.value))}
          fieldDisabled={props.disabled}
          inputProps={{
            maxLength: '14',
            inputmode: 'numeric',
          }}
          sx={{
            '& .MuiFormLabel-root': {
              background: '#ffffff',
              fontFamily: theme.typography.bodyMediumBook,
              fontWeight: '300',
            },
          }}
        />
      )}
    </Grid>
  )
}

export default RJSFPhoneField

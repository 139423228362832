/* eslint-disable react/prop-types */
import { Container, TAGTypography } from '@aspendental/design-system'
import { useNavigate } from '@reach/router'
import React from 'react'
import Spinner from './Spinner'
import { SessionType } from '../../constants/type'
export interface IGraphQLTemplateProps {
  loading: boolean
  error: Error | undefined
  data: any
  children: any
  sessionItem?: SessionType
  errorNavigate?: string
}
const GraphQLTemplate = (props: IGraphQLTemplateProps) => {
  const navigate = useNavigate()
  const AUTH_NOT_AUTHENTICATED = 'The current user is not authorized to access this resource.'
  if (props.error) {
    if (props.error.message === AUTH_NOT_AUTHENTICATED) {
      globalThis?.sessionStorage.removeItem(props.sessionItem ? props.sessionItem : 'isLogin')
      navigate(props.errorNavigate ? props.errorNavigate : '/patient-services/login')
    }
    return <TAGTypography variant="h2">{JSON.stringify(props.error.message)}</TAGTypography>
  }
  if (props.loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }
  if (!props.data) {
    return <p>Nothing to show...</p>
  }
  if (props.data) {
    return props.children
  }
}
export default GraphQLTemplate

import React from 'react'
import {
  Box,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TAGButton,
  TAGSvgIcon,
  TAGTypography,
  useTheme,
} from '@aspendental/design-system'
import { TAGColorType } from '@aspendental/design-system/lib/constants/type'
import { formatPhoneNumber } from '../../utils/Helper'

interface ICancelModalContentProps {
  onCloseDrawer: () => void
  handleClose?: () => void
  onApply?: () => void
  disableReschedule?: boolean
  isNormalSize: boolean
  appointment: string
  modalContent: any
  appointmentChangesDisabled: boolean
  facilityPhoneNumber: string
}

const CancelModalContent = (props: ICancelModalContentProps) => {
  const theme = useTheme()
  return (
    <>
      <DialogContent className="dialogContent">
        <TAGTypography variant="h2" sx={{ textAlign: props.isNormalSize ? 'start' : 'center' }}>
          {props.modalContent.modalTitle}
        </TAGTypography>
        <Box sx={{ marginY: '16px' }}>
          <TAGTypography variant="h3" sx={{ textAlign: props.isNormalSize ? 'start' : 'center' }}>
            {props.appointment}
          </TAGTypography>
          {props.appointmentChangesDisabled ? (
            <Box mt={2.5} sx={{ textAlign: props.isNormalSize ? 'start' : 'center' }}>
              <TAGTypography variant="bodySmallBook" color="text.secondary">
                {props.modalContent.bodyText} {formatPhoneNumber(props.facilityPhoneNumber)}.
              </TAGTypography>
            </Box>
          ) : (
            props.disableReschedule && (
              <ListItem sx={{ alignItems: 'flex-start', padding: '20px 0 0 0' }}>
                <ListItemAvatar sx={{ minWidth: '30px', padding: '8px 0' }}>
                  <TAGSvgIcon icon="CircleWarning" size={24} sx={{ color: 'text.secondary' }}></TAGSvgIcon>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <TAGTypography variant="bodyMediumBold" color="text.secondary">
                      {props.modalContent.headlineText}
                    </TAGTypography>
                  }
                  secondary={
                    <TAGTypography variant="bodySmallBook" color="text.secondary">
                      {`We recommend you call ${formatPhoneNumber(
                        props.facilityPhoneNumber,
                      )} to reschedule your appointment.`}
                    </TAGTypography>
                  }
                />
              </ListItem>
            )
          )}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: props.isNormalSize ? theme.palette.backgrounds.neutral : theme.palette.backgrounds.white,
          display: 'flex',
          flexDirection: props.isNormalSize ? 'row' : 'column',
          paddingY: '16px',
        }}
      >
        {props.appointmentChangesDisabled ? (
          <TAGButton
            buttonVariant="Secondary Default"
            fullWidth={!props.isNormalSize}
            sx={{ margin: '0.3rem' }}
            onClick={() => {
              if (!props.isNormalSize) {
                window.open(`tel:+1${props.facilityPhoneNumber}`)
              }
              props.onCloseDrawer()
            }}
          >
            {props.isNormalSize ? props.modalContent.primaryButtonText : props.modalContent.secondaryButtonText}
          </TAGButton>
        ) : (
          <>
            <TAGButton
              buttonVariant={props.isNormalSize ? 'Secondary Default' : 'Primary Default'}
              onClick={() => {
                props.handleClose && props.handleClose()
                props.onCloseDrawer()
              }}
              className="cancelButton"
              fullWidth={!props.isNormalSize}
              sx={{ margin: '5px' }}
            >
              <TAGTypography
                variant="buttonLarge"
                color={props.isNormalSize ? ('text.default' as TAGColorType) : ('text.light' as TAGColorType)}
              >
                {props.modalContent?.primaryButtonText ?? 'Cancel appointment'}
              </TAGTypography>
            </TAGButton>

            {!props.disableReschedule && (
              <TAGButton
                buttonVariant={props.isNormalSize ? 'Primary Default' : 'Secondary Default'}
                fullWidth={!props.isNormalSize}
                disabled={props.disableReschedule}
                sx={{ margin: '5px' }}
                onClick={() => {
                  props.onApply && props.onApply()
                  props.onCloseDrawer()
                }}
              >
                {props.modalContent?.secondaryButtonText ?? 'Reschedule appointment'}
              </TAGButton>
            )}
          </>
        )}
      </DialogActions>
    </>
  )
}

export default CancelModalContent

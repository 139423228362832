import { getEnv } from '@aspendental/design-system'

export const getConfig = () => {
  const env = getEnv()

  if (env === 'prod') {
    return {
      ENTERPRISE_API_URL: 'https://api.aspendental.com/',
    }
  }

  return {
    ENTERPRISE_API_URL: 'https://apistg.aspendental.com/',
  }
}

import { FormActions, FormActionTypes } from './form.actions'

export const defaultFormState = {
  forms: {},
}
export const formReducer: React.Reducer<any, FormActions> = (state: any, action: FormActions) => {
  const updatedForm = state.forms
  switch (action.type) {
    case FormActionTypes.ADD_FORM:
      updatedForm[action.payload.formName] = action.payload.form
      return {
        ...state,
        forms: updatedForm,
      }
  }
}

import React from 'react'
import { TAGSelectContainer, TAGTypography, Box } from '@aspendental/design-system'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { format } from 'date-fns'
import { timeWithoutTimeZone, toCamelCase } from '../../../utils/Helper'

interface ITimButtonListProps {
  avaliableTimes: { timeslot: string; timeslotUid: string }[]
  facilityCode: string
  isNormalSize: boolean
  updateSeletedButton: (btn: string) => void
  seletedBtn: string | undefined
  date: string
}

const TimeButtonList = (props: ITimButtonListProps) => {
  const CHECKICONSTYLE = {
    height: '13.33px',
    width: '13.33px',
    position: 'absolute',
    top: '2px',
    right: '3px',
  }
  const showTime = (time: string) => `${format(timeWithoutTimeZone(time), 'p')}`

  const dateHandler = (btn: string) => {
    props.updateSeletedButton(btn)
  }
  return (
    <>
      {props.avaliableTimes.map((time, i) => (
        <Box key={`${time}${i}`} sx={{ padding: '6px' }}>
          <TAGSelectContainer
            className={toCamelCase(`${props.facilityCode}$${time.timeslot}${time.timeslotUid}`)}
            value={`${props.facilityCode}$${time}${time.timeslotUid}`}
            selected={props.seletedBtn === `${props.facilityCode}$${time.timeslot}${time.timeslotUid}`}
            onClick={() => dateHandler(`${props.facilityCode}$${time.timeslot}${time.timeslotUid}`)}
            sx={{
              width: '90px',
              borderWidth: '1px',
              '&:hover': {
                borderWidth: '1px',
                borderColor: props.isNormalSize && '#D6D9DA',
              },
            }}
            color={'secondary.main'}
            id={`${props.facilityCode}_timeslot_${props.date}_${showTime(time.timeslot)}`}
          >
            {props.seletedBtn === `${props.facilityCode}$${time.timeslot}${time.timeslotUid}` ? (
              <CheckCircleIcon sx={CHECKICONSTYLE} />
            ) : null}
            <TAGTypography
              variant={
                props.seletedBtn === `${props.facilityCode}$${time.timeslot}${time.timeslotUid}`
                  ? 'bodySmallBold'
                  : 'bodySmallBook'
              }
              color="secondary.main"
            >
              {showTime(time.timeslot)}
            </TAGTypography>
          </TAGSelectContainer>
        </Box>
      ))}
    </>
  )
}

export default TimeButtonList

import React, { ReactNode, useEffect, useState } from 'react'
import endpoints from '../../services/endpoints'
import { navigate } from '@reach/router'
import { Container } from '@aspendental/design-system'
import Spinner from '../GraphQLTemplate/Spinner'
export interface IOneClickPrePage {
  enfant: ReactNode
  pageContent: any
  isNormalSize: boolean
  path: string
}

export interface IOneClickProps {
  visitId?: string
  pageContent?: any
  isNormalSize?: boolean
  path?: string
}

const OneClickPrePage = (props: IOneClickPrePage) => {
  const [isTokenValidated, setIsTokenValidated] = useState(false)
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const token = urlParams.get('token') ?? ''
  const visitId = urlParams.get('appointmentId') ?? ''

  useEffect(() => {
    const validateToken = async () => {
      if (token) {
        const isAuthenticated =
          globalThis?.sessionStorage.getItem('isLogin') || globalThis?.sessionStorage.getItem('isOneClickLogin')

        if (isAuthenticated) {
          globalThis?.sessionStorage.removeItem('isLogin')
          globalThis?.sessionStorage.removeItem('isOneClickLogin')
        }
        try {
          const response = await endpoints.validateToken(token)
          // if success, response will be only true, not an object,
          // if fail, the response will be an object, which contain response.ok === false and response.status !== 200
          if (typeof response === 'object' && !response?.ok) {
            navigate('/patient-services/one-click-expiration')
          } else {
            globalThis?.sessionStorage.setItem('isOneClickLogin', 'true')
          }
          setIsTokenValidated(true)
        } catch (err) {
          navigate('/patient-services/one-click-expiration')
        }
      } else {
        navigate('/patient-services/')
      }
    }
    validateToken()
  }, [])

  if (!isTokenValidated) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return React.cloneElement(props.enfant as React.ReactElement<IOneClickProps>, {
    ...props,
    visitId: visitId,
  })
}

export default OneClickPrePage

export type UserContextType = {
  user: { [key: string]: string }
  rescheduleForm: { [key: string]: any }
  visitInfo: { [key: string]: any }
  addVisitInfo: (_visitInfo: object) => void
  addUserData: (user: object) => void
  addRescheduleForm: (_rescheduleForm: object) => void
}

export const enum UserActionTypes {
  ADD_USER,
  ADD_RESCHEDULE_FORM,
  ADD_VISIT_INFO,
}

export interface UserAction {
  type: UserActionTypes
}

export interface AddVisitInfo {
  visitInfo: object
  type: UserActionTypes.ADD_VISIT_INFO
}
export interface AddUserData {
  user: object
  type: UserActionTypes.ADD_USER
}
export interface addRescheduleForm {
  rescheduleForm: object
  type: UserActionTypes.ADD_RESCHEDULE_FORM
}

export type UserActions = AddUserData | addRescheduleForm | AddVisitInfo

import React from 'react'
import { useMutation } from '@apollo/client'
import {
  CardActions,
  CardContent,
  DialogActions,
  TAGButton,
  TAGLink,
  TAGModal,
  TAGTextButton,
  TAGTypography,
  Tooltip,
  dataLayerConfig,
  Stack,
  Box,
  TAGSvgIcon,
} from '@aspendental/design-system'
import { TAGModalTheme } from '@aspendental/design-system/lib/components/elements/TAGModal/TAGModal.type'
import { useNavigate } from '@reach/router'
import { format, isAfter } from 'date-fns'
import { CANCEL_VISIT } from '../../constants/graphqlquery'
import { CancelVisitInput } from '../../contexts/formContext/form.actions'
import CancellationModal from '../CancellationModal/CancellationModal'
import CancelModalContent from './CancelModalContent'
import { addToCalendar, formatPhoneNumber, timeWithoutTimeZone } from '../../utils/Helper'
import UserContext from '../../contexts/userContext/user-context'

const appointment24HoursOrLess = (appointmentTimeString: string) => {
  try {
    const currentBrowserTime = new Date()
    const appointmentTime = new Date(appointmentTimeString)
    const millisecondsPerDay = 24 * 60 * 60 * 1000
    const twentyFourHoursFromNow = new Date(currentBrowserTime.getTime() + millisecondsPerDay)

    return isAfter(twentyFourHoursFromNow, appointmentTime)
  } catch (e) {
    console.error('ERROR: @appointment24HoursOrLess: ', e)
    return false
  }
}
interface IAppointmentCardProp {
  isNormalSize: boolean
  contentCard: any
  modalContent: any
  apptInfo: {
    classDescription: string
    scheduledDateTime: string
    curAppointmentCode: string
    visitId: string
    facility: any
    duration: string
    canBeCanceled: boolean
    canBeRescheduled: boolean
    firstName: string
    lastName: string
    birthDate: string
  }
  initModal?: boolean
  homeOfficePhone: string
}
const AppointmentCard = (props: IAppointmentCardProp) => {
  const appointmentWithin24Hours = appointment24HoursOrLess(props.apptInfo?.scheduledDateTime)
  const userCtx = React.useContext(UserContext)
  const isOneClickLogin = globalThis?.sessionStorage.getItem('isOneClickLogin') === 'true'
  const [showModel, setShowModel] = React.useState(props.initModal ?? false)
  const [dialogInfo, setDialogInfo] = React.useState<{
    bodyText: string
    headlineText: string
    primaryButtonText: string
    secondaryButtonText: string
    theme: TAGModalTheme
  } | null>(null)
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false)
  const navigate = useNavigate()
  const canBeRescheduled = props.apptInfo?.canBeRescheduled
  const canBeCanceled = props.apptInfo?.canBeCanceled
  const params = window.location.search
  const [cancelVisit] = useMutation<{ me: { cancelVisit: { params: CancelVisitInput; errors: any } } }>(CANCEL_VISIT)
  const showCancelModel = () => {
    setShowModel(true)
  }
  const closeCancelModel = () => {
    setShowModel(false)
  }
  const rescheduleHandler = () => {
    userCtx.addVisitInfo({
      code: props.apptInfo.facility.code,
      visitId: props.apptInfo.visitId,
      phoneNumber: props.apptInfo.facility.phoneNumber,
    })
    userCtx.addUserData({
      firstName: props.apptInfo.firstName,
      lastName: props.apptInfo.lastName,
      dateOfBirth: props.apptInfo.birthDate,
    })
    if (canBeRescheduled) {
      dataLayerConfig({
        event: 'patient_services_dashboard',
        step_name: 'dashboard_action',
        link_text: props.contentCard.buttonText,
        link_url: 'https://www.aspendental.com/patient-services/reschedule',
      })
      isOneClickLogin
        ? navigate(`/patient-services/reschedule-appointment/${params}`)
        : navigate(props.contentCard.buttonUrl)
    }
  }
  const cancelAppointment = async () => {
    try {
      const response = await cancelVisit({
        variables: {
          input: {
            visitId: props.apptInfo.visitId,
          },
        },
      })
      dataLayerConfig({
        event: 'cancel_pop_up',
        link_text: 'Cancel Appointment',
        link_url: 'https://www.aspendental.com/patient-services',
      })
      if (response?.data?.me?.cancelVisit?.errors) {
        const gqlError = response?.data?.me?.cancelVisit?.errors[0]?.code
        setDialogInfo({
          bodyText: JSON.stringify(gqlError),
          headlineText: JSON.stringify(gqlError),
          primaryButtonText: 'Dismiss',
          secondaryButtonText: '',
          theme: TAGModalTheme.WithRedErrorIcon,
        })
        setOpenWarningDialog(true)
        return
      }
      isOneClickLogin ? navigate('/patient-services/appointment/cancel/success') : navigate('/patient-services')
    } catch (e: any) {
      setDialogInfo({
        bodyText: JSON.stringify(e.message),
        headlineText: JSON.stringify(e.message),
        primaryButtonText: 'Dismiss',
        secondaryButtonText: '',
        theme: TAGModalTheme.WithRedErrorIcon,
      })
      setOpenWarningDialog(true)
    }
  }
  const handleWarningDialogClose = () => {
    setOpenWarningDialog(false)
  }
  const address2 = `${props.apptInfo?.facility?.address?.city}, ${props.apptInfo?.facility?.address?.stateCode} ${props.apptInfo?.facility?.address?.zipCode}`

  // existing user with less than 24 hours until appointment or cannot be cancelled
  const appointmentChangesDisabled = (appointmentWithin24Hours && canBeRescheduled === false) || canBeCanceled === false

  const CardTitle = () => {
    return props.apptInfo.scheduledDateTime ? (
      <TAGTypography fontStyle="h1" gutterBottom>
        {props.contentCard.title}
      </TAGTypography>
    ) : (
      <Box sx={{ textAlign: 'center' }}>
        <TAGSvgIcon icon={'Calendar'} />
        <br />
        <TAGTypography fontStyle="h1" variant="h2">
          You don&apos;t have any future scheduled appointments.
        </TAGTypography>
      </Box>
    )
  }

  const AppointmentTime = () => {
    return (
      <TAGTypography fontStyle="bodyXLargeBook" gutterBottom>
        {`${format(timeWithoutTimeZone(props.apptInfo?.scheduledDateTime), 'EEE. MMMM d, p')} | ${
          props.apptInfo.duration
        } min`}
      </TAGTypography>
    )
  }

  const AddToCalendar = () => {
    return (
      <TAGLink
        underline="hover"
        sx={{
          cursor: 'pointer',
        }}
        onClick={() =>
          addToCalendar(
            props.apptInfo?.scheduledDateTime,
            'Aspen dental Appointment',
            props.apptInfo?.facility?.address.address1,
            address2,
          )
        }
        eventType="patient_services_dashboard"
        linkText="Add to calendar"
        linkUrl="#"
        stepName="dashboard_action"
        variant={'buttonLarge'}
      >
        Add to calendar
      </TAGLink>
    )
  }

  const AppointmentCancellation = () => {
    return (
      <CancellationModal
        handleClose={closeCancelModel}
        handleOpen={showCancelModel}
        open={showModel}
        isNormalSize={props.isNormalSize}
        buttonName={'Cancel Appointment'}
        isOneClick={isOneClickLogin}
        appointmentChangesDisabled={appointmentChangesDisabled}
      >
        <CancelModalContent
          appointment={`${format(timeWithoutTimeZone(props.apptInfo?.scheduledDateTime), 'EEE. MMMM d, p')}`}
          isNormalSize={props.isNormalSize}
          onCloseDrawer={closeCancelModel}
          onApply={rescheduleHandler}
          handleClose={cancelAppointment}
          disableReschedule={!canBeRescheduled}
          modalContent={appointmentChangesDisabled ? props.modalContent[2] : props.modalContent[1]}
          appointmentChangesDisabled={appointmentChangesDisabled}
          facilityPhoneNumber={props.apptInfo?.facility.phoneNumber}
        />
      </CancellationModal>
    )
  }

  const AppointmentChangesDisabled = () => {
    return (
      <CardContent sx={{ textAlign: 'start', color: 'white' }}>
        <Stack>
          <TAGTypography>
            Need to Cancel or Reschedule? Call{' '}
            <TAGLink
              underline="hover"
              color="neutrals.white"
              sx={{
                cursor: 'pointer',
              }}
              variant={'bodyMediumBook'}
              href={`tel:+1${props.apptInfo?.facility?.phoneNumber}`}
            >
              {formatPhoneNumber(props.apptInfo.facility.phoneNumber)}
            </TAGLink>{' '}
            to change
          </TAGTypography>
        </Stack>
      </CardContent>
    )
  }

  return (
    <React.Fragment>
      <CardContent
        sx={{ textAlign: 'start', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
      >
        <CardTitle />
        <br />
        <br />
        {props.apptInfo.scheduledDateTime && <AppointmentTime />}
        {!props.homeOfficePhone && (
          <Box
            sx={{
              display: 'inline-flex',
              flexDirection: 'column',
              padding: '16px 24px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
              textAlign: 'center',
            }}
          >
            <TAGTypography variant="h2" fontStyle="h2">
              Schedule your first appointment
            </TAGTypography>
            <TAGButton
              buttonVariant="Secondary High Contrast"
              href={`${globalThis.location.origin}/schedule-an-appointment/`}
            >
              Schedule Appointment
            </TAGButton>
          </Box>
        )}
        <br />

        <br />
        {props.apptInfo.scheduledDateTime && <AddToCalendar />}
        <br />
      </CardContent>
      {!props.apptInfo.scheduledDateTime && props.homeOfficePhone && (
        <TAGTypography color="text.light" variant="bodySmallBook">
          Need Help? Call{' '}
          <TAGLink
            underline="hover"
            color="primary.light"
            sx={{
              cursor: 'pointer',
            }}
            variant={'bodySmallBook'}
            href={`tel:+1${props?.homeOfficePhone}`}
          >
            {formatPhoneNumber(props?.homeOfficePhone)}
          </TAGLink>
        </TAGTypography>
      )}
      {!appointmentChangesDisabled && (
        <CardActions
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: props.isNormalSize ? 'row' : 'column',
          }}
        >
          {canBeRescheduled && (
            <Tooltip title={canBeRescheduled ? '' : 'Unable to rescheduled'} placement="top">
              <span>
                <TAGButton
                  buttonVariant={'Primary High Contrast'}
                  buttonSize={'small'}
                  fullWidth={false}
                  sx={{ margin: '8px' }}
                  disabled={!canBeRescheduled}
                  onClick={rescheduleHandler}
                >
                  {props.contentCard.buttonText}
                </TAGButton>
              </span>
            </Tooltip>
          )}
          {props.apptInfo.scheduledDateTime && <AppointmentCancellation />}
        </CardActions>
      )}

      {!canBeRescheduled && !appointmentChangesDisabled && props.apptInfo.facility && (
        <Box
          sx={{
            color: 'white',
            textAlign: 'start',
            paddingLeft: '1rem',
          }}
        >
          <TAGTypography>
            Call{' '}
            <TAGLink
              underline="hover"
              color="neutrals.white"
              sx={{
                cursor: 'pointer',
              }}
              variant={'bodyMediumBook'}
              href={`tel:+1${props.apptInfo?.facility?.phoneNumber}`}
            >
              {formatPhoneNumber(props.apptInfo.facility.phoneNumber)}
            </TAGLink>{' '}
            to reschedule
          </TAGTypography>
        </Box>
      )}

      {/* existing user with appointment in the next 24 hours. */}
      {appointmentChangesDisabled && (
        <>
          <AppointmentChangesDisabled />
          <AppointmentCancellation />
        </>
      )}

      <TAGModal
        {...props}
        className="WarningModal"
        open={openWarningDialog}
        bodyText={dialogInfo?.bodyText || ''}
        darkScrim={false}
        handleClose={handleWarningDialogClose}
        headlineText={dialogInfo?.headlineText || ''}
        buttonGroup={
          <DialogActions>
            <TAGTextButton onClick={handleWarningDialogClose}>{dialogInfo?.primaryButtonText}</TAGTextButton>
          </DialogActions>
        }
        theme={dialogInfo?.theme || TAGModalTheme.WithRedErrorIcon}
      />
    </React.Fragment>
  )
}

export default AppointmentCard

import React from 'react'
import { Redirect } from '@reach/router'

interface IRequireAuthProps {
  enfant: any
  pageContent: any
  isNormalSize: boolean
  path: string
}
const RequireAuth = (props: IRequireAuthProps) => {
  const isAuthenticated = globalThis?.sessionStorage.getItem('isLogin')
  return (
    <>
      {isAuthenticated ? (
        React.cloneElement(props.enfant as React.ReactElement<any>, {
          ...props,
        })
      ) : (
        //AuthPage
        <Redirect to={props.pageContent.pages[10].path} noThrow />
      )}
    </>
  )
}

export default RequireAuth

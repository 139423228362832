import { FormHelperText, TAGTypography } from '@aspendental/design-system'
import React from 'react'
export const renderFromHelper = (error: string) => {
  if (!error) {
    return
  } else {
    return (
      <FormHelperText
        error
        sx={{
          marginLeft: 0,
          '&.Mui-error': {
            color: 'error.dark',
          },
        }}
      >
        <TAGTypography variant="lowestLevel">{error}</TAGTypography>
      </FormHelperText>
    )
  }
}

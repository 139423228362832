/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { UserContextType } from './user.actions'
const initialState: UserContextType = {
  user: {},
  rescheduleForm: {},
  visitInfo: {},
  addVisitInfo: (_visitInfo: object) => {},
  addUserData: (_user: object) => {},
  addRescheduleForm: (_rescheduleForm: object) => {},
}
const UserContext = React.createContext(initialState)

export default UserContext

import React from 'react'
import { DialogActions, TAGModal, TAGTextButton } from '@aspendental/design-system'
import { RESCHEDULE_VISIT } from '../../constants/graphqlquery'
import UserContext from '../../contexts/userContext/user-context'
import { useMutation } from '@apollo/client'
import { TAGModalTheme } from '@aspendental/design-system/lib/components/elements/TAGModal/TAGModal.type'
import { Router, useNavigate, Redirect } from '@reach/router'
import RescheduleReviewPage from '../RescheduleReviewPage/RescheduleReviewPage'
import RescheduleSuccessPage from '../RescheduleSuccessPage/RescheduleSuccessPage'
import RescheduleTemplate from './RescheduleTemplate'
import { RescheduleVisitInput } from '../../contexts/formContext/form.actions'
import RescheduleForm from './RescheduleForm'
import { SessionType } from '../../constants/type'

export interface IReschedulePageProps {
  isNormalSize?: boolean
  path?: string
  pageContent?: any
  sessionItem?: SessionType
  errorNavigate?: string
  visitId?: string
}

const ReschedulePage = (props: IReschedulePageProps) => {
  const navigate = useNavigate()
  const formRef = React.useRef<any>()
  const userCtx = React.useContext(UserContext)
  const isOneClickLogin = globalThis?.sessionStorage.getItem('isOneClickLogin') === 'true'
  const content = props.pageContent.pages[8]
  const [rescheduleVisit] =
    useMutation<{ me: { rescheduleVisit: { params: RescheduleVisitInput; errors: any } } }>(RESCHEDULE_VISIT)
  const setForm = (form: any) => {
    if (form) {
      formRef.current = form
    }
  }
  const [disableButton, setDisableButton] = React.useState(false)
  const [dialogInfo, setDialogInfo] = React.useState<{
    bodyText: string
    headlineText: string
    primaryButtonText: string
    secondaryButtonText: string
    theme: TAGModalTheme
  } | null>(null)
  const [openWarningDialog, setOpenWarningDialog] = React.useState(false)
  const handleWarningDialogClose = () => {
    setOpenWarningDialog(false)
  }

  const confirmHandler = async () => {
    try {
      setDisableButton(true)
      const appointment = userCtx.rescheduleForm.appointmentTimecode?.split('$')
      const response = await rescheduleVisit({
        variables: {
          input: {
            visitId: userCtx.visitInfo.visitId,
            timeslotUid: appointment[1].substring(29),
          },
        },
      })
      if (response.errors || response?.data?.me?.rescheduleVisit?.errors) {
        const gqlError = response?.data?.me?.rescheduleVisit?.errors[0]?.code
        setDialogInfo({
          bodyText: JSON.stringify(gqlError),
          headlineText: JSON.stringify(gqlError),
          primaryButtonText: 'Dismiss',
          secondaryButtonText: '',
          theme: TAGModalTheme.WithRedErrorIcon,
        })
        setOpenWarningDialog(true)
        return
      }
      if (isOneClickLogin) {
        navigate(`/patient-services/reschedule-appointment/success`)
        globalThis?.sessionStorage.removeItem('isOneClickLogin')
        return
      }
      return navigate(`/patient-services/reschedule/success`)
    } catch (e: any) {
      setDisableButton(false)
      setDialogInfo({
        bodyText: JSON.stringify(e.message),
        headlineText: JSON.stringify(e.message),
        primaryButtonText: 'Dismiss',
        secondaryButtonText: '',
        theme: TAGModalTheme.WithRedErrorIcon,
      })
      setOpenWarningDialog(true)
    }
  }
  return (
    <>
      <Router>
        <RescheduleTemplate
          path={'/'}
          isNormalSize={props.isNormalSize ?? true}
          buttonHandler={(event: any) => formRef.current.onSubmit(event)}
          buttonText={content.contents[0].continueButtonText}
          headingText={content.contents[0].headingText}
          needAssistanceText={content.contents[0].needAssistanceText}
          eventType='patient_services_reschedule_start'
          stepName='reschedule_start'
          enfant={
            <RescheduleForm
              setForm={setForm}
              isNormalSize={props.isNormalSize ?? true}
              pageContent={content.contents[0].steps[0]}
              modalContent={content.contents[1]}
              visitId={props.visitId}
              sessionItem={props.sessionItem}
              errorNavigate={props.errorNavigate}
            />
          }
        />
        <RescheduleTemplate
          path={`/review`}
          isNormalSize={props.isNormalSize ?? true}
          headingText={content.contents[0].headingText}
          buttonText={content.contents[0].confirmButtonText}
          needAssistanceText={content.contents[0].needAssistanceText}
          disabledButton={disableButton}
          buttonHandler={confirmHandler}
          eventType='patient_services_reschedule'
          stepName='reschedule_review'
          enfant={
            <RescheduleReviewPage
              isNormalSize={props.isNormalSize ?? true}
              pageContent={content.contents[0].steps[1]}
              sessionItem={props.sessionItem}
              errorNavigate={props.errorNavigate}
            />
          }
        />
        <RescheduleTemplate
          path={'/success'}
          isNormalSize={props.isNormalSize ?? true}
          headingText={content.contents[0].headingScheduledText}
          needAssistanceText={content.contents[0].needAssistanceText}
          eventType='patient_services_reschedule_confirmation'
          stepName='reschedule_confirmation'
          enfant={
            <RescheduleSuccessPage
              isNormalSize={props.isNormalSize ?? true}
              pageContent={content.contents[0].steps[2]}
              sessionItem={props.sessionItem}
            />
          }
        />
        <Redirect from="/*" to={isOneClickLogin ? '/patient-services/' : '/patient-services/login'} noThrow />
      </Router>
      <TAGModal
        {...props}
        className="WarningModal"
        open={openWarningDialog}
        bodyText={dialogInfo?.bodyText || ''}
        darkScrim={false}
        handleClose={handleWarningDialogClose}
        headlineText={dialogInfo?.headlineText || ''}
        buttonGroup={
          <DialogActions>
            <TAGTextButton onClick={handleWarningDialogClose}>{dialogInfo?.primaryButtonText}</TAGTextButton>
          </DialogActions>
        }
        theme={dialogInfo?.theme || TAGModalTheme.WithRedErrorIcon}
      />
    </>
  )
}

export default ReschedulePage
